import {CircularProgress, FormControl, InputLabel} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {Field, FormikProps} from 'formik'
import {isArray} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {AutoSelectDropdown} from '../../../common/AutoSelectDropdown'
import {DropdownOption} from '../../../common/DropdownOption'
import {useAdminPilotUser} from '../../../common/hooks/useAdminPilotUser'
import {CountriesDropdown} from '../../../components/CountriesDropdown/CountriesDropdown'
import {DynamicSelectDataScopeInput} from '../../../modules/DataScopeConfiguration'
import {
  CHANGE_COMMON_DATASCOPE_VALUE,
  GroupedRoleReducerAction
} from '../../../modules/GroupedRoleAssignmentReducer'
import {GroupedRoleAssignment} from '../../../modules/ManageUsers.selectors'
import {RoleOptions} from '../RoleEditLayout'
import {checkRequired} from '../utils'

const useStyles = makeStyles((theme) => ({
  multiSelect: {
    paddingTop: theme.spacing(2.5)
  }
}))

interface Props {
  form: FormikProps<GroupedRoleAssignment>
  isRequired: boolean
  errorMessage: any
  configuration: DynamicSelectDataScopeInput
  dataScopeName: string
  dispatchRoleStateUpdate: React.Dispatch<GroupedRoleReducerAction>
  multiSelect: boolean
  shouldDisplayAllInDropdown?: boolean
  selectedOptions: RoleOptions
  setSelectedOptions: (options: RoleOptions) => void
  groupedRoleAssignment: GroupedRoleAssignment
}

// eslint-disable-next-line complexity
export const DynamicSelectDataScope: React.FC<Props> = ({
  form,
  isRequired,
  errorMessage,
  configuration,
  dataScopeName,
  dispatchRoleStateUpdate,
  multiSelect,
  shouldDisplayAllInDropdown = true,
  selectedOptions,
  setSelectedOptions,
  groupedRoleAssignment
}) => {
  const classes = useStyles()
  const {t} = useTranslation()

  const fieldName = `commonDataScopes.${dataScopeName}`

  const dependencies =
    configuration.dependsOn !== undefined
      ? configuration.dependsOn
          .map((dep) => form.values.commonDataScopes[dep])
          .filter((dependency) => dependency !== undefined)
      : []

  const dependenciesMet = dependencies.every((dep) => !!dep)

  const helperText = !dependenciesMet
    ? t('roleAssignment.selectFirst', {
        dataScope: t(
          `roleAssignment.dataScope.${
            configuration.dependsOn[dependencies.findIndex((dep) => !dep)]
          }`
        )
      })
    : errorMessage

  const isUserAdminPilot = useAdminPilotUser()

  const {
    isLoading,
    isError,
    data: options
  } = useQuery<DropdownOption[], AxiosError>(
    [dataScopeName, ...dependencies],
    async () => {
      return configuration.fetchValues(dependencies)
    },
    {enabled: !isUserAdminPilot}
  )

  if (isLoading) {
    return (
      <FormControl key={dataScopeName} margin="normal" className={classes.multiSelect} fullWidth>
        <InputLabel shrink variant="standard">
          {t(`roleAssignment.dataScope.${dataScopeName}`)}
        </InputLabel>
        <CircularProgress size={20} />
      </FormControl>
    )
  }

  if (isError) {
    return (
      <FormControl key={dataScopeName} margin="normal" className={classes.multiSelect} fullWidth>
        <InputLabel shrink variant="standard">
          {t(`roleAssignment.dataScope.${dataScopeName}`)}
        </InputLabel>
        <div>{t('roleAssignment.labelFetchError')}</div>
      </FormControl>
    )
  }

  const allOption =
    shouldDisplayAllInDropdown && options
      ? [{key: ' ', label: t('roleAssignment.selectAll')}, ...options]
      : options

  // Empty string as value is luckily understood by backend and MaterialUI
  const dropdownOptions = isRequired ? options : allOption

  const countriesData = window.intlTelInputGlobals.getCountryData()

  const countryDataFilter = (countryId: string) =>
    countryId === 'MOCK'
      ? {name: 'MOCK', iso2: 'MOCK', dialCode: 'MOCK'}
      : countriesData.find((countryData) => countryData.iso2.toUpperCase() === countryId)

  const formattedCountryData =
    dropdownOptions &&
    dropdownOptions
      .filter((countryId) => countryDataFilter(countryId.key))
      .map((countryId) => {
        return {
          label: countryDataFilter(countryId.key)?.name ?? '',
          value: countryDataFilter(countryId.key)?.iso2.toUpperCase() ?? ''
        }
      })
      .sort((current, next) => {
        if (current.label < next.label) {
          return -1
        }
        if (current.label > next.label) {
          return 1
        }
        return 0
      })

  const SelectDropdown = dataScopeName === 'countryId' ? CountriesDropdown : AutoSelectDropdown
  const isAdminPilotSingleOption =
    isUserAdminPilot &&
    ((dataScopeName === 'countryId' && selectedOptions.countryId.isSingle) ||
      (dataScopeName === 'orgUnitId' && selectedOptions.orgUnitId.isSingle) ||
      (dataScopeName === 'businessLine' && selectedOptions.businessLine.isSingle))
  const shouldDisableCustomerAdminInputs =
    isAdminPilotSingleOption &&
    (groupedRoleAssignment.variantDataScopes.customersScopes.length > 0 ||
      (groupedRoleAssignment.variantDataScopes.payerIds &&
        groupedRoleAssignment.variantDataScopes.payerIds?.length > 0))

  return (
    <Field
      key={dataScopeName}
      name={fieldName}
      validate={checkRequired(
        isRequired,
        multiSelect,
        t('roleAssignment.dataScopeRequired', {
          dataScope: t(`roleAssignment.dataScope.${dataScopeName}`)
        })
      )}
    >
      {({field, form}: any) => (
        <SelectDropdown
          label={t(`roleAssignment.dataScope.${dataScopeName}`)}
          select
          required={isRequired}
          margin="normal"
          disabled={
            shouldDisableCustomerAdminInputs ||
            (selectedOptions[dataScopeName] !== undefined &&
            !selectedOptions[dataScopeName].isSingle
              ? false
              : !dependenciesMet || !dropdownOptions || !dropdownOptions.length)
          }
          fullWidth
          error={errorMessage}
          helperText={helperText}
          data-test-id={`selectdropdown-${fieldName}`}
          {...field}
          autoSelectSingleOption={configuration.dependsOn.every(
            (e: string) => !!form.values.commonDataScopes[e]
          )}
          setFieldValue={(name, value) => {
            form.setFieldValue(name, value)

            dispatchRoleStateUpdate({
              type: CHANGE_COMMON_DATASCOPE_VALUE,
              payload: {
                name: dataScopeName,
                value: multiSelect ? [value].concat() : value
              }
            })
          }}
          SelectProps={{
            multiple: multiSelect
          }}
          name={fieldName}
          onChange={(event) => {
            if (isUserAdminPilot) {
              setSelectedOptions({
                ...selectedOptions,
                [dataScopeName]: {
                  isSingle: false,
                  value: event.target.value,
                  dropdownOptions: selectedOptions[dataScopeName].dropdownOptions
                }
              })
            } else {
              field.onChange(event)
            }
            dispatchRoleStateUpdate({
              type: CHANGE_COMMON_DATASCOPE_VALUE,
              payload: {
                name: dataScopeName,
                value: multiSelect
                  ? isArray(event.target.value)
                    ? event.target.value
                    : [event.target.value]
                  : event.target.value
              }
            })
          }}
          value={
            isUserAdminPilot
              ? selectedOptions[dataScopeName]?.value || ''
              : form.values.commonDataScopes[dataScopeName] || (multiSelect ? [] : '')
          }
          {...(dataScopeName === 'countryId'
            ? {countriesList: formattedCountryData}
            : {
                options: (selectedOptions[dataScopeName]?.dropdownOptions &&
                selectedOptions[dataScopeName].dropdownOptions.length > 0
                  ? selectedOptions[dataScopeName].dropdownOptions
                  : dropdownOptions
                )?.map((option: any) => ({
                  value: option.key,
                  label: option.label
                }))
              })}
        />
      )}
    </Field>
  )
}
