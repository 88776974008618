import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import {AppInsightsAdapter} from '@hconnect/common/logging'
import {withTheme} from '@hconnect/uikit'
import {overrideHConnectTheme} from '@hconnect/uikit/src/lib2'
import {CircularProgress, CssBaseline as CssBaselineMui} from '@mui/material'
import {Theme, ThemeProvider} from '@mui/material/styles'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {identity} from 'lodash'
import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'
import {HelmetProvider} from 'react-helmet-async'
import {
  QueryClient as QueryClientV3,
  QueryClientProvider as QueryClientProviderV3
} from 'react-query'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'

import {App} from './App'
import {loginFlow, store} from './App.store'
import Head from './components/HtmlHead/HtmlHead'
import Localization from './localization'
import {
  fetchLoggedInUserPermissions,
  fetchLoggedInUserProfile,
  storeJWTData
} from './modules/LoggedInUser.actions'

// import customSW from './customSW'
AppInsightsAdapter.init()
AppInsightsAdapter.allowTracking()

// Setup Localization
void Localization()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const queryClientV3 = new QueryClientV3({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const customBreakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1280,
    xl: 1920
  }
}

// Note: for unknown reason in unit test overrideDefaultTheme is undefined
// therefore it will be defaulted to identity function
const adminconsoleTheme = (overrideHConnectTheme ?? identity)({
  palette: {
    primary: {
      main: '#29aaff'
    }
  },
  typography: {
    caption: {
      opacity: 1
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        root: ({theme}: {theme: Theme}) => ({
          body: {
            background:
              'linear-gradient(90deg, rgba(0,55,77,1) 0%, rgba(0,99,138,1) 40%,rgba(0,99,138,1) 60%, rgba(0,55,77,1) 100%)'
          },
          'body *::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          },
          'body *::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 4px rgba(0,0,0,0.3)',
            backgroundColor: theme.palette.primary.dark
          },
          'body *::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.main
          }
        })
      }
    }
  },
  breakpoints: customBreakpoints
})

function render(CurrentApp: React.ElementType) {
  // @ts-expect-error
  const AppRoot = withTheme({})(CurrentApp)
  const rootElement = document.getElementById('root')

  ReactDOM.render(
    <Suspense fallback={<CircularProgress />}>
      <HelmetProvider>
        <Head />
        <ThemeProvider theme={adminconsoleTheme}>
          <CssBaselineMui />
          <QueryClientProvider client={queryClient}>
            <QueryClientProviderV3 client={queryClientV3}>
              <Provider store={store}>
                <BrowserRouter>
                  <AppRoot />
                </BrowserRouter>
              </Provider>
            </QueryClientProviderV3>
          </QueryClientProvider>
        </ThemeProvider>
      </HelmetProvider>
    </Suspense>,
    rootElement
  )
}

async function start(): Promise<void> {
  const loginResponse = await loginFlow.getLoginState()
  if (!loginResponse.loggedIn) {
    try {
      await loginFlow.startLoginProcess()
    } catch (error) {
      console.error('Could not redirect to Authenticator: ', error)
    }
    return
  }

  store.dispatch(
    // TODO: why do we store this information, it is redundant
    storeJWTData({
      id: loginResponse.decodedToken.user_id,
      email: loginResponse.decodedToken.email,
      country: loginResponse.decodedToken.country_code,
      name: loginResponse.decodedToken.given_name
    })
  )
  store.dispatch(fetchLoggedInUserPermissions(loginResponse.decodedToken.user_id))
  store.dispatch(fetchLoggedInUserProfile(loginResponse.decodedToken.user_id))

  render(App)
}

start().catch((error) => {
  console.error('Could not start application: ', error)
})
