import {Country, FeatureConstraint} from '@hconnect/apiclient'
import {Feature} from '@hconnect/common/components/FeaturesCheck'
import {TableColumnType, Typography} from '@hconnect/uikit'
import {Box, Chip} from '@mui/material'
import camelcase from 'camelcase'
import {TFunction} from 'i18next'
import React from 'react'

type FeaturesCollapsedListRowsProps = {
  countries?: Country[]
  t: TFunction
}
export const FeaturesCollapsedListRows = ({countries, t}: FeaturesCollapsedListRowsProps) => {
  const rowsPool: {[key: string]: TableColumnType} = {
    constraint: {
      field: 'constraints',
      headerName: t('features.constraints'),
      headerAlign: 'left',
      renderCell: (featureConstraint: FeatureConstraint) => {
        const dataScopes = JSON.parse(featureConstraint.dataScope)
        return (
          <Box>
            <Typography style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
              {Object.keys(dataScopes).map((key) => {
                const formattedKey = camelcase(key, {
                  pascalCase: true
                }).replace(/([a-z])([A-Z])/g, '$1 $2')

                const country = countries?.find(
                  (country) => country.countryCode === dataScopes[key].toString()
                )?.countryName

                return `${key === 'countryId' ? 'Country' : formattedKey}: ${
                  key === 'roleType'
                    ? t(`roleAssignment.roleTypes.${dataScopes[key]}`)
                    : key === 'countryId' && country
                    ? country
                    : typeof dataScopes[key] === 'object'
                    ? JSON.stringify(dataScopes[key])
                    : dataScopes[key]
                }\n`
              })}
            </Typography>
          </Box>
        )
      }
    },
    type: {
      field: 'type',
      headerName: 'Type',
      headerAlign: 'left',
      filterable: true,
      renderCell: (feature: Feature) => {
        return <Typography>{feature?.type || '-'}</Typography>
      }
    },
    enabled: {
      field: 'enabled',
      headerName: t('features.enabled'),
      headerAlign: 'left',
      renderCell: (feature: Feature) => {
        return (
          <Box>
            {feature.enabled !== undefined && (
              <Chip
                label={t(`features.${feature.enabled ? 'enabled' : 'disabled'}`)}
                style={{
                  background: feature.enabled ? '#009D93' : '#DA0901',
                  color: '#FFFFFF',
                  margin: '4px 8px 0 0'
                }}
                size="small"
              />
            )}
          </Box>
        )
      }
    },
    expander: {
      field: 'expander',
      renderCell: () => {
        return <Box style={{width: '100%'}} />
      }
    }
  }

  const rowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.constraint,
    rowsPool.type,
    rowsPool.enabled
  ]
  return rowOrder(rowsPool)
}
