import {useEffect} from 'react'

import {TableColumnType} from '../types'

export const useStoreColumns = (
  filteredColumns: TableColumnType[],
  columns: TableColumnType[],
  storeFiltersOptions?: {
    tableName: string
    userId: string
  }
) => {
  const storedColumns = localStorage.getItem(
    `activeColumns-${storeFiltersOptions?.tableName}-${storeFiltersOptions?.userId}`
  )
  useEffect(() => {
    const selectableColumns = columns.filter((column) => !column.noColumnSelection)
    const parsedStoredColumns = storedColumns && (JSON.parse(storedColumns) as string[])
    storeFiltersOptions &&
      filteredColumns.length !== selectableColumns.length &&
      localStorage.setItem(
        `activeColumns-${storeFiltersOptions?.tableName}-${storeFiltersOptions?.userId}`,
        JSON.stringify(filteredColumns.map((column) => column.field))
      )

    parsedStoredColumns &&
      selectableColumns.length === filteredColumns.length &&
      localStorage.removeItem(
        `activeColumns-${storeFiltersOptions?.tableName}-${storeFiltersOptions?.userId}`
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredColumns])

  return {storedColumns}
}
