import {getOrgUnits} from '@hconnect/apiclient'
import {useQuery} from '@tanstack/react-query'

import {api} from '../App.store'

export const useOrgUnit = (countryId: string, businessLine: string, refetchOnMount?: boolean) =>
  useQuery(
    ['orgUnitId', countryId, businessLine],
    async () => {
      const response = await getOrgUnits(api)(countryId, {businessLine})
      return response.data.map((orgUnit) => ({
        key: orgUnit.orgUnitId,
        label: orgUnit.orgUnitName
      }))
    },
    {refetchOnMount}
  )
