import {Typography} from '@hconnect/uikit'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Radio,
  RadioGroup
} from '@material-ui/core'
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {Controller, ControllerRenderProps} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {AnnouncementFormData} from '../Announcement.form'
import {CheckboxForm} from '../Announcements.components'

import {useStyles} from './styles'
import {AnnouncementFormProps} from './types'

export type ProductsInputProps = AnnouncementFormProps

export const ProductsInput = ({formMethods}: ProductsInputProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const pillar = formMethods.watch('pillarType')
  const selectedProducts = formMethods.watch('products')

  const [allProducts, setAllProducts] = useState(-1)

  const products = {
    hconnect: [
      {label: t('announcements.form.hconnect.hub'), value: 'Hub'},
      // Disabled until Onsite is ready to handle announcements
      // {label: t('announcements.form.hconnect.onsite'), value: 'OnSite'},
      {label: t('announcements.form.hconnect.mhc'), value: 'MHC'}
    ],
    hproduce: [
      {label: t('announcements.form.hproduce.planner'), value: 'planner'},
      {label: t('announcements.form.hproduce.cockpit'), value: 'cockpit'},
      {label: t('announcements.form.hproduce.predict'), value: 'predict'},
      {label: t('announcements.form.hproduce.maintain'), value: 'maintain'}
    ]
  }
  useEffect(() => {
    if (selectedProducts.length === products.hproduce.length) {
      setAllProducts(1)
    } else if (selectedProducts.length > 0) {
      setAllProducts(0)
    } else {
      setAllProducts(-1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProducts, selectedProducts])

  const onSelectAllProducts = (checked: boolean) => {
    if (checked) {
      setAllProducts(1)
      formMethods.setValue(
        'products',
        products.hproduce.map((value) => value.value)
      )
    } else {
      setAllProducts(-1)
      formMethods.setValue('products', [])
    }
  }

  const hconnectProducts = (field: ControllerRenderProps<AnnouncementFormData, 'products'>) => (
    <RadioGroup
      {...field}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '60%',
        marginTop: '15px'
      }}
    >
      {products.hconnect.map((value) => (
        <FormControlLabel
          key={value.value}
          control={
            <Radio
              name={value.value}
              value={value.value}
              checked={field.value.includes(value.value)}
              data-test-id={`${value.value}-radiobutton`}
              classes={{root: classes.radio}}
              icon={<span className={clsx(classes.radioIcon, classes.uncheckedBorder)} />}
              checkedIcon={<span className={clsx(classes.radioIcon, classes.checkedIcon)} />}
            />
          }
          label={value.label}
          style={{paddingRight: '10px'}}
        />
      ))}
    </RadioGroup>
  )

  const hproduceProducts = (field: ControllerRenderProps<AnnouncementFormData, 'products'>) => (
    <FormControl>
      <FormControlLabel
        style={{marginLeft: -10, marginTop: '15px'}}
        control={
          <CheckboxForm
            dataTestId="select-all-products-checkbox"
            indeterminate={allProducts === 0}
            checked={allProducts > -1}
            onChange={(_, checked) => onSelectAllProducts(checked)}
          />
        }
        label={t('announcements.form.selectAllHProduce')}
      />
      <FormGroup>
        {products.hproduce.map((value) => (
          <FormControlLabel
            key={value.value}
            control={
              <CheckboxForm
                name={value.value}
                dataTestId={`${value.value}-checkbox`}
                checked={field.value.includes(value.value)}
                onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  if (checked) {
                    const values = new Set(field.value)
                    values.add(value.value)
                    field.onChange([...values])
                  } else {
                    const values = new Set(field.value)
                    values.delete(value.value)
                    field.onChange([...values])
                  }
                }}
              />
            }
            label={value.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
  return (
    <Box>
      <FormControl>
        <Typography style={{fontSize: '18px', fontWeight: 600}}>
          {t('announcements.products')}
        </Typography>
        <Controller
          control={formMethods.control}
          name="products"
          rules={{
            validate: (value) => value.length > 0
          }}
          render={({field}) => {
            return (
              <>
                {pillar === 'hConnect' ? hconnectProducts(field) : hproduceProducts(field)}
                {formMethods.formState.errors.products?.type === 'validate' ? (
                  <FormHelperText
                    style={{marginLeft: 0}}
                    component="div"
                    error={formMethods.formState.errors.products?.type === 'validate'}
                  >
                    {t('announcements.form.validateProducts')}
                  </FormHelperText>
                ) : null}
              </>
            )
          }}
        />
      </FormControl>
    </Box>
  )
}
