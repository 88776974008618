import {Country} from '@hconnect/apiclient'
import {Feature} from '@hconnect/common/components/FeaturesCheck'
import {TableColumnType, Typography, useBreakPoints, useTranslation} from '@hconnect/uikit'
import {TableFilterTypeProps} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/TableFilter.enum'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {Box, Chip, IconButton} from '@mui/material'
import camelcase from 'camelcase'
import React from 'react'

import {countryDataByCountryCode} from '../../common/utils'

import {Countries} from './components/Countries'

type FeaturesListRowsProps = {
  setIsSnackbarOpen: (opened: boolean) => void
  countries?: Country[]
  handleEditFeature: (feature: Feature) => void
  handleDeleteFeature: (featureName: string) => void
  canEditFeatures?: boolean
}
export const FeaturesListRows = ({
  countries,
  setIsSnackbarOpen,
  handleEditFeature,
  handleDeleteFeature,
  canEditFeatures
}: FeaturesListRowsProps) => {
  const {t} = useTranslation()
  const screenSizes = useBreakPoints()
  const isExtraLarge = ['xl'].includes(screenSizes)
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const countriesData = window.intlTelInputGlobals.getCountryData()

  const countryDataFilter = (countryId?: string | null) =>
    countryDataByCountryCode(countriesData, countryId)
  const copyText = async (text: string) => {
    await navigator.clipboard.writeText(text).then(() => setIsSnackbarOpen(true))
  }

  const rowsPool: {[key: string]: TableColumnType} = {
    name: {
      field: 'name',
      mobileRowHeader: true,
      headerName: t('features.name'),
      headerAlign: 'left',
      sortable: true,
      filterable: true,
      renderCell: (feature: Feature) => (
        <Typography
          style={{
            whiteSpace: 'pre-wrap',
            fontSize: '14px',
            wordBreak: isMobile ? 'break-word' : undefined
          }}
        >
          {feature.name}
        </Typography>
      )
    },
    description: {
      field: 'description',
      headerName: t('features.description'),
      headerAlign: 'left',
      filterable: true,
      renderCell: (feature: Feature) => (
        <Typography
          style={{
            minWidth: isMobile ? undefined : '300px',
            whiteSpace: 'pre-line',
            fontSize: '14px'
          }}
        >
          {feature.description || '-'}
        </Typography>
      )
    },
    tags: {
      field: 'tags',
      headerName: t('features.tags'),
      headerAlign: 'left',
      filterable: true,
      renderCell: (feature: Feature) => {
        return (
          <Typography>
            {feature.tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                style={{
                  background: '#0F89FF',
                  color: '#FFFFFF',
                  margin: '4px 8px 0 0'
                }}
                size="small"
              />
            ))}
          </Typography>
        )
      }
    },
    type: {
      field: 'type',
      headerName: t('features.type'),
      headerAlign: 'left',
      filterable: true,
      renderCell: (feature: Feature) => {
        return (
          <Typography style={{fontSize: '14px'}}>
            {feature.constraints === undefined || feature.constraints?.length < 2
              ? feature.constraints?.map((constraint) => {
                  const dataScopes = JSON.parse(constraint.dataScope as string)
                  return Object.keys(dataScopes).length < 5
                    ? feature.constraints?.[0].type || '-'
                    : '...'
                }) || '-'
              : '...'}
          </Typography>
        )
      }
    },
    country: {
      field: 'country',
      headerName: t('features.country'),
      headerAlign: 'left',
      filterable: true,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: countries
        ? countries.map(({countryName, countryCode}) => ({
            label: countryDataFilter(countryCode)?.name ?? countryName,
            value: countryCode
          }))
        : [],
      filterMultiSelect: true,
      renderCell: (feature: Feature) => (
        <Countries
          countryCodes={
            feature.constraints === undefined
              ? []
              : feature.constraints
                  .map((constraint) => {
                    const dataScopes = JSON.parse(constraint.dataScope as string)
                    return Object.keys(dataScopes).map((key) => {
                      const country = countryDataFilter(dataScopes[key].toString())?.name
                      return key === 'countryId' || (key === 'country' && country) ? country : ''
                    })[0] as string
                  })
                  .filter((obj) => obj)
          }
          countries={countries}
        />
      )
    },
    constraints: {
      field: 'constraints',
      headerName: t('features.constraints'),
      headerAlign: 'left',
      mobileCollapsed: true,
      renderCell: (feature: Feature) => {
        return (
          <Box>
            {feature.constraints === undefined || feature.constraints?.length < 2 ? (
              feature.constraints?.map((constraint, index) => {
                const dataScopes = JSON.parse(constraint.dataScope as string)
                return (
                  <Box key={index}>
                    <Typography
                      style={{
                        whiteSpace: 'pre-line',
                        wordBreak: 'break-word',
                        minWidth: isMobile ? undefined : '250px',
                        fontSize: '14px'
                      }}
                    >
                      {Object.keys(dataScopes).length < 5
                        ? Object.keys(dataScopes).map((key) => {
                            const formattedKey = camelcase(key, {
                              pascalCase: true
                            }).replace(/([a-z])([A-Z])/g, '$1 $2')

                            const country = countryDataFilter(dataScopes[key].toString())?.name

                            return `${key === 'countryId' ? 'Country' : formattedKey}: ${
                              key === 'roleType'
                                ? t(`roleAssignment.roleTypes.${dataScopes[key]}`)
                                : key === 'countryId' && country
                                ? country
                                : typeof dataScopes[key] === 'object'
                                ? JSON.stringify(dataScopes[key])
                                : dataScopes[key]
                            }\n`
                          })
                        : '...'}
                    </Typography>
                  </Box>
                )
              }) || <Box>-</Box>
            ) : (
              <Box>...</Box>
            )}
          </Box>
        )
      }
    },
    enabled: {
      field: 'enabled',
      headerName: t('features.enabled'),
      headerAlign: 'left',
      filterable: true,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterShowSearch: false,
      filterDropdownOptions: [
        {label: t('features.enabled'), value: 'true'},
        {label: t('features.disabled'), value: 'false'}
      ],
      renderCell: (feature: Feature) => {
        return (
          <Box>
            {feature.enabled !== undefined || feature.constraints?.length === 1 ? (
              <Chip
                label={t(
                  `features.${
                    feature.enabled || feature.constraints?.[0].enabled ? 'enabled' : 'disabled'
                  }`
                )}
                style={{
                  background:
                    feature.enabled || feature.constraints?.[0].enabled ? '#009D93' : '#DA0901',
                  color: '#FFFFFF',
                  margin: '4px 8px 0 0'
                }}
                size="small"
              />
            ) : (
              '...'
            )}
          </Box>
        )
      }
    },
    actions: {
      field: 'actions',
      headerName: isMobile ? '' : t('features.action'),
      headerAlign: 'left',
      renderCell: (feature: Feature) => {
        return (
          <Box
            style={{
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'start',
              alignItems: 'center'
            }}
          >
            <IconButton
              style={{boxShadow: 'none'}}
              data-test-id={`action-copy-json-${feature.name}`}
              onClick={() => copyText(JSON.stringify(feature))}
            >
              <ContentCopyIcon style={{color: '#016AD4'}} />
            </IconButton>
            {canEditFeatures && (
              <>
                <IconButton
                  style={{boxShadow: 'none'}}
                  onClick={() => handleEditFeature(feature)}
                  data-test-id={`action-edit-${feature.name}`}
                >
                  <ModeEditOutlineIcon style={{color: '#016AD4'}} />
                </IconButton>
                <IconButton
                  style={{boxShadow: 'none'}}
                  onClick={() => handleDeleteFeature(feature.name)}
                  data-test-id={`action-delete-${feature.name}`}
                >
                  <DeleteOutlineIcon style={{color: '#016AD4'}} />
                </IconButton>
              </>
            )}
          </Box>
        )
      }
    },
    expander: {
      field: 'expander',
      noColumnSelection: true,
      renderCell: () => {
        return <Box style={{width: '100%'}} />
      }
    }
  }

  const rowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.actions,
    rowsPool.name,
    rowsPool.description,
    rowsPool.tags,
    rowsPool.type,
    rowsPool.country,
    rowsPool.constraints,
    rowsPool.enabled,
    ...(isExtraLarge ? [rowsPool.expander] : [])
  ]

  const mobileRowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.name,
    rowsPool.description,
    rowsPool.tags,
    rowsPool.type,
    rowsPool.country,
    rowsPool.constraints,
    rowsPool.enabled,
    rowsPool.actions
  ]

  return (isMobile ? mobileRowOrder : rowOrder)(rowsPool)
}
