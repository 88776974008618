import {Divider, MenuItem, MenuList, Link} from '@material-ui/core'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import React from 'react'
import {NavLink as NavLinkComponent} from 'react-router-dom'

import {useNavLinkStyles} from '../styles'
import {BurgerMenuProps, NavItem} from '../types'

import {MenuLinksBox} from './MenuLinksBox'

interface NavMenuLinksProps
  extends Pick<BurgerMenuProps, 'navItems' | 'data-test-id' | 'analytics'> {
  onSelect?: (selectedItem?: NavItem) => void
  isMobile?: boolean
}

export const NavMenuLinks: React.FC<NavMenuLinksProps> = ({
  navItems = [],
  onSelect,
  analytics,
  'data-test-id': dataTestId,
  isMobile = true
}) => {
  const {
    activeLink,
    disabledLink,
    link,
    verticalLink,
    divider,
    subVerticalLink,
    subDivider,
    pointerEvents,
    noPointerEvents
  } = useNavLinkStyles()

  const [drillInOpen, setDrillInOpen] = React.useState(-1)

  const debounceHandleMouseLeave = React.useCallback(
    debounce(() => handleMouseLeave(), 500),
    []
  )

  const debounceHandleMouseEnter = React.useCallback(
    debounce((newValue: number) => handleMouseEnter(newValue), 500),
    []
  )
  React.useEffect(() => {
    return () => {
      setDrillInOpen(-1)
    }
  }, [])

  const handleMouseEnter = (index: number) => setDrillInOpen(index)
  const handleMouseLeave = () => setDrillInOpen(-1)
  return (
    <MenuList disablePadding data-test-id={dataTestId}>
      {navItems.flatMap((navItem, index) => [
        <Link
          underline="none"
          key={`${navItem.label}-${navItem.url}`}
          component={NavLinkComponent}
          to={navItem.url}
          activeClassName={classNames(activeLink, {
            [noPointerEvents]: !(navItem.subItems && navItem.subItems.length > 0),
            [pointerEvents]: navItem.subItems && navItem.subItems.length > 0 && !isMobile
          })}
          className={classNames(link, {[disabledLink]: !!navItem.isDisabled})}
          data-test-id={
            navItem.dataTestId &&
            (dataTestId ? `${navItem.dataTestId}-${dataTestId}` : navItem.dataTestId)
          }
          onClick={() => {
            onSelect && onSelect(navItem)
            analytics && analytics(navItem.url)
          }}
        >
          <MenuItem
            className={verticalLink}
            onMouseEnter={() => !isMobile && debounceHandleMouseEnter(index)}
          >
            {navItem.label}
            {navItem.subItems && navItem.subItems.length > 0 && !isMobile ? (
              <KeyboardArrowRight fontSize="inherit" />
            ) : null}
          </MenuItem>
          {!isMobile && drillInOpen >= 0 ? (
            <MenuLinksBox
              onMouseLeave={debounceHandleMouseLeave}
              sx={{
                position: 'absolute',
                left: '100%',
                top: 0,
                boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.12)',
                paddingLeft: 'auto',
                paddingRight: 'auto'
              }}
            >
              <NavMenuLinks
                navItems={navItems[drillInOpen].subItems}
                data-test-id={`${dataTestId}-drillIn`}
                isMobile={isMobile}
              />
            </MenuLinksBox>
          ) : null}
        </Link>,
        navItem.subItems && navItem.subItems.length > 0 && isMobile
          ? navItem.subItems.map((subItem, subIndex) => (
              <Link
                underline="none"
                key={`${subItem.label}-${subItem.url}`}
                component={NavLinkComponent}
                to={subItem.url}
                activeClassName={classNames(activeLink, pointerEvents)}
                className={classNames(link, {
                  [disabledLink]: !!navItem.isDisabled || !!subItem.isDisabled
                })}
                data-test-id={subItem.dataTestId || subItem.label}
                onClick={() => {
                  onSelect && onSelect(subItem)
                  analytics && analytics(subItem.url)
                }}
              >
                {navItem.subItems && subIndex === 0 && (
                  <Divider orientation="horizontal" color="soft" className={divider} />
                )}
                <MenuItem className={classNames(verticalLink, subVerticalLink)}>
                  {subItem.label}
                </MenuItem>
                {navItem.subItems && subIndex < navItem.subItems.length - 1 && (
                  <Divider
                    orientation="horizontal"
                    color="soft"
                    className={classNames(divider, subDivider)}
                  />
                )}
              </Link>
            ))
          : null,
        index < navItems.length - 1 ? (
          <Divider
            key={`${navItem.label}-${navItem.url}-divider`}
            orientation="horizontal"
            color="soft"
            className={divider}
          />
        ) : null
      ])}
    </MenuList>
  )
}
