import {Typography, useBreakPoints} from '@hconnect/uikit'
import CloseIcon from '@mui/icons-material/Close'
import {Box, Button, Chip, IconButton, TextField, FormControlLabel, Checkbox} from '@mui/material'
import classNames from 'classnames'
import clsx from 'clsx'
import {isEmpty} from 'lodash'
import upperFirst from 'lodash/upperFirst'
import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {DetailsField} from '../../components/DetailsField/DetailsField'
import {DiscardDialog} from '../Announcements/components/dialogs/DiscardDialog'

import {useStyles} from './AudienceDetails.styles'

type AudienceFilter = {
  name: string
  value: string
}

type AudienceFormProps = {
  audienceName: string
  audienceDescription: string
  audienceFilters: AudienceFilter[]
}
interface AudienceDetailsProps {
  filters: AudienceFilter[]
  handleClose: () => void
}
// eslint-disable-next-line complexity
export const AudienceDetails = ({filters, handleClose}: AudienceDetailsProps) => {
  const screenSizes = useBreakPoints()
  const {t} = useTranslation()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const {classes} = useStyles()
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const [shouldOpenDiscardDialog, setShouldOpenDiscardDialog] = useState(false)

  const {handleSubmit, clearErrors, formState, control, setValue} = useForm<AudienceFormProps>({
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    if (shouldRedirect) {
      handleClose()
    }
  }, [shouldRedirect])

  useEffect(() => {
    clearErrors('audienceFilters')
    setValue('audienceFilters', filters)
  }, [filters])

  const handleAudienceSubmit = () => {
    console.log('Audience created')
  }

  return (
    <>
      <form style={{height: '100%'}} onSubmit={handleSubmit(handleAudienceSubmit)}>
        <Box
          data-test-id="userList-details"
          style={{display: 'flex', flexDirection: 'column', height: '100%'}}
        >
          <Box data-test-id="userList-details-actions" style={{marginBottom: '40px'}}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              {!isMobile && (
                <Typography style={{fontWeight: 600, fontSize: '16px', color: '#54708C'}}>
                  {t('usersList.audience.title')}
                </Typography>
              )}
              <Box
                style={{
                  display: 'flex',
                  justifyContent: isMobile ? 'center' : 'flex-start',
                  width: isMobile ? '100%' : 'auto',
                  gap: isMobile ? '16px' : 0
                }}
              >
                {!isMobile && (
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => setShouldOpenDiscardDialog(true)}
                      style={{boxShadow: 'none'}}
                      data-test-id="close-userList-details-button"
                    >
                      <CloseIcon style={{color: '#016AD4'}} />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%'
            }}
          >
            <Box style={{display: 'flex', flexDirection: 'column', gap: '18px'}}>
              <Controller
                name="audienceName"
                control={control}
                rules={{required: true}}
                render={({field}) => (
                  <TextField
                    {...field}
                    className={classes.textField}
                    type="text"
                    variant="standard"
                    placeholder={t('usersList.audience.name')}
                    InputProps={{disableUnderline: true, inputProps: {style: {padding: 0}}}}
                    error={!!formState.errors.audienceName}
                    helperText={
                      formState.errors.audienceName?.type === 'required' &&
                      t('usersList.audience.required', {field: t('usersList.audience.name')})
                    }
                  />
                )}
              />
              <Controller
                name="audienceDescription"
                control={control}
                render={({field}) => (
                  <TextField
                    {...field}
                    style={{
                      borderRadius: '4px',
                      padding: '12px',
                      border: '1px solid rgba(209, 217, 224, 1)'
                    }}
                    type="text"
                    variant="standard"
                    placeholder={t('usersList.audience.description')}
                    InputProps={{disableUnderline: true, inputProps: {style: {padding: 0}}}}
                    multiline={true}
                    minRows={3}
                    error={!!formState.errors.audienceDescription}
                    helperText={
                      formState.errors.audienceDescription?.type === 'required' &&
                      t('usersList.audience.required', {field: t('usersList.audience.description')})
                    }
                  />
                )}
              />
              <Box style={{flex: '0 1 calc(50% - 12px)'}}>
                <Controller
                  name="audienceFilters"
                  control={control}
                  rules={{
                    validate: (value) => value && value.length > 0
                  }}
                  render={() => (
                    <DetailsField
                      label={t('usersList.audience.filters')}
                      gutter
                      dataTestId="userList-details-products-field"
                    >
                      <Box style={{marginBottom: '4px'}} />
                      {filters?.map((filter) => (
                        <Chip
                          data-test-id={`filter-chip-${upperFirst(filter.name)}`}
                          classes={{
                            root: classNames({
                              [classes.tags]: true,
                              [classes.tagMod1]: true
                            })
                          }}
                          style={{marginRight: '4px'}}
                          key={filter.name}
                          label={upperFirst(filter.name) + ': ' + filter.value}
                          size="small"
                        />
                      ))}
                      {(formState.errors.audienceFilters || filters.length === 0) && (
                        <Typography
                          style={{
                            color: formState.errors.audienceFilters ? '#CC0801' : '#52667A'
                          }}
                        >
                          {t('usersList.audience.filtersRequired')}
                        </Typography>
                      )}
                    </DetailsField>
                  )}
                />
              </Box>
            </Box>
            <Box style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
              <FormControlLabel
                style={{fontWeight: 500, color: '#29333D', whiteSpace: 'nowrap'}}
                control={
                  <Checkbox
                    onChange={() => {}}
                    data-test-id="checkbox-create-another-user"
                    icon={<span className={clsx(classes.checkboxIcon, classes.uncheckedBorder)} />}
                    checkedIcon={
                      <span className={clsx(classes.checkboxIcon, classes.checkboxCheckedIcon)} />
                    }
                  />
                }
                label={t('usersList.audience.immediateMessage')}
              />
              <Button
                className={classes.saveButton}
                disabled={!isEmpty(formState.errors)}
                type="submit"
              >
                {t('usersList.audience.save')}
              </Button>
              <Button
                className={classes.discardButton}
                onClick={() => setShouldOpenDiscardDialog(true)}
              >
                {t('usersList.audience.discard')}
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
      <DiscardDialog
        setShouldRedirect={setShouldRedirect}
        setShouldOpenDiscardDialog={setShouldOpenDiscardDialog}
        shouldOpenDiscardDialog={shouldOpenDiscardDialog}
      />
    </>
  )
}
