import {InputLabelProps, makeStyles, TextField, TextFieldProps} from '@material-ui/core'
import classNames from 'classnames'
import i18next from 'i18next'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import React, {forwardRef, useCallback, useEffect, useRef, useState} from 'react'

import {BorderedTextField} from './BorderedTextField'

const useStyles = makeStyles(() => ({
  inputLabelFocused: {},
  inputLabel: {
    '&:not($inputLabelFocused)': {
      transform: 'translate(52px, 24px) scale(1);'
    },
    '&:not([data-shrink="true"])': {
      transform: 'translate(52px, 24px) scale(1);'
    }
  },
  phoneNumberTextField: ({readonlyMode, filled}: PhoneNumberTextFieldProps) => ({
    '& .iti': {
      width: '100%'
    },
    '& .iti input': {
      width: 'calc(100% - 36px)'
    },
    '& .iti div': {
      display: readonlyMode ? 'none' : 'flex'
    },
    '& .iti.iti--allow-dropdown input': {
      width: 'calc(100% - 58px)'
    },
    '& .iti__flag-container': {
      top: filled ? '27px' : undefined,
      left: filled ? '5px' : undefined,
      height: filled ? '20px' : undefined
    }
  })
}))

export interface SelectedValues {
  inputValue: string
  selectedCountryCode: string
}

interface PhoneNumberTextFieldProps {
  className?: string
  onPhoneChange?: (
    phoneNumber: string,
    isValidNumber: boolean,
    itiGetCountry?: intlTelInput.CountryData
  ) => void
  options?: intlTelInput.Options
  InputLabelProps?: Partial<InputLabelProps>
  bordered?: boolean
  readonlyMode?: boolean
  filled?: boolean
}

export const PhoneNumberTextField = forwardRef<
  HTMLInputElement,
  TextFieldProps & PhoneNumberTextFieldProps
>(
  (
    {
      className,
      onPhoneChange,
      options = {},
      bordered,
      InputLabelProps,
      readonlyMode,
      ...otherProps
    },
    ref
  ) => {
    const classes = useStyles({readonlyMode, filled: otherProps.variant === 'filled'})
    const itiRef = useRef<intlTelInput.Plugin>()
    const inputRef = useRef<HTMLInputElement>()
    const [selectedCountryDialCode, setSelectedCountryDialCode] = useState('')

    const isPhoneNumberValid = () => {
      const itiGetNumber = itiRef.current?.getNumber() ?? ''
      const isValidNumber = itiRef.current?.isValidNumber() ?? false
      return isValidNumber || !itiGetNumber
    }

    const handleCountryChange = useCallback(() => {
      if (!onPhoneChange) {
        return
      }
      const itiGetNumber = itiRef.current?.getNumber() ?? ''
      const itiGetCountry = itiRef.current?.getSelectedCountryData()

      onPhoneChange(itiGetNumber, isPhoneNumberValid(), itiGetCountry)
    }, [onPhoneChange])

    const localeToIso = (userLocale: string) => {
      if (!userLocale.includes('-')) {
        return userLocale
      }
      return userLocale.split('-')[1]
    }

    const getUserCountryCode = useCallback((callback: (countryCode: string) => void) => {
      try {
        return callback(localeToIso(i18next.language))
      } catch (err) {
        console.log('TCL: getUserCountryCode -> err', err)
      }
    }, [])

    useEffect(() => {
      const defaults: intlTelInput.Options = {
        preferredCountries: ['us', 'ca', 'ru', 'nl', 'gb', 'pl', 'de', 'au', 'ba', 'hk', 'ge'],
        autoPlaceholder: 'off',
        initialCountry: 'auto',
        geoIpLookup: getUserCountryCode
      }

      if (inputRef && inputRef.current) {
        // dont remove try catch, app will crash if initializing fails
        try {
          itiRef.current = intlTelInput(inputRef.current, {
            ...defaults,
            ...options
          })
        } catch (e) {
          console.error(e)
        }
      }

      // @ts-ignore
      if (!window.intlTelInputUtils) {
        void import('intl-tel-input/build/js/utils').then(() => {
          ;(itiRef?.current as intlTelInput.Plugin & {handleUtils: () => void})?.handleUtils()
        })
      }

      return () => {
        itiRef.current?.destroy()
      }
    }, [readonlyMode])

    useEffect(() => {
      if (options.initialCountry) {
        itiRef.current?.setCountry(options.initialCountry)
        handleCountryChange()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.initialCountry])

    useEffect(() => {
      const inputEl = inputRef.current
      inputEl?.addEventListener('countrychange', handleCountryChange)
      return () => {
        inputEl?.removeEventListener('countrychange', handleCountryChange)
      }
    }, [handleCountryChange])

    useEffect(() => {
      setSelectedCountryDialCode(itiRef.current?.getSelectedCountryData().dialCode ?? '')
    }, [readonlyMode])

    useEffect(() => {
      if (
        !readonlyMode &&
        selectedCountryDialCode !== itiRef.current?.getSelectedCountryData().dialCode &&
        !itiRef.current?.getNumber().includes(itiRef.current?.getSelectedCountryData().dialCode)
      ) {
        setSelectedCountryDialCode(itiRef.current?.getSelectedCountryData().dialCode ?? '')

        const itiGetNumber = '+' + (itiRef.current?.getSelectedCountryData().dialCode ?? '')

        if (!onPhoneChange || isNaN(Number(itiGetNumber)) || !selectedCountryDialCode) {
          return
        }
        onPhoneChange(itiGetNumber, isPhoneNumberValid())
      }
    }, [itiRef.current?.getSelectedCountryData().dialCode])

    const isNumberValid = isPhoneNumberValid()
    const PhoneTextField = bordered ? BorderedTextField : TextField
    return (
      <PhoneTextField
        type="tel"
        autoComplete="tel"
        error={!isNumberValid}
        value={itiRef.current?.getNumber()}
        {...otherProps}
        onChange={handleCountryChange}
        inputRef={inputRef}
        className={classNames(classes.phoneNumberTextField, className)}
        InputLabelProps={{
          classes: {
            focused: classes.inputLabelFocused,
            shrink: classes.inputLabelFocused,
            root: classes.inputLabel
          },
          ...InputLabelProps
        }}
      />
    )
  }
)
