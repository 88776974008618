import {Customer, FetchUsersParameters, Payer, User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import type {QueryFunction} from '@tanstack/react-query'
import {useQuery} from '@tanstack/react-query'
import {AxiosError, AxiosResponse} from 'axios'

import {api} from '../../../App.store'
import {useLoggedInUser} from '../../../common/hooks/useLoggedInUser'
import {noResultsFromCustomerLookupLabel, noResultsFromPayerLookupLabel} from '../UsersList'
import {UserIssueKeys, UsersTableFilter} from '../UsersList.types'

type QueryKey = [string, {filter: UsersTableFilter; loggedInUserId?: string}]

// TODO: cleanup, refactor. This has just been extracted from UsersList.
const mapUserIssueKeyToQueryParams = (userIssue: UserIssueKeys) => {
  if (userIssue === UserIssueKeys.ALL) {
    return
  }
  if (userIssue === UserIssueKeys.NO_PSW) {
    return {
      [UserIssueKeys.NEVER_LOGGED_IN]: true,
      [UserIssueKeys.IS_INVITE_SENT]: false
    }
  }
  if (userIssue === UserIssueKeys.IS_INVITE_SENT || userIssue === UserIssueKeys.HAS_ROLES) {
    return {[userIssue]: false}
  }
  if (
    userIssue === UserIssueKeys.NEVER_LOGGED_IN ||
    userIssue === UserIssueKeys.IS_LOCKED_OUT ||
    userIssue === UserIssueKeys.FAILED_LOGIN
  ) {
    return {[userIssue]: true}
  }
}

const getUsers = (
  url: string,
  filter: UsersTableFilter,
  loggedInUserId: string | undefined,
  customers?: string | string[],
  payers?: string | string[]
) => {
  const params: FetchUsersParameters = {
    ...filter,
    skip: (filter.page - 1) * filter.rowsPerPage,
    limit: filter.rowsPerPage,
    isInternal: filter.isInternal,
    country: filter.country,
    roleType: filter.roleType === 'all' ? undefined : filter.roleType,
    isTester: filter.isTester,
    marketId: filter.marketId,
    // CreatedBy should be refactored to something more speaking
    createdBy: filter.createdBy ? loggedInUserId : undefined,
    lastActivityProduct: filter.lastActivityProduct,
    customerId: Array.isArray(customers) ? customers.join(',') : customers,
    payerId: Array.isArray(payers) ? payers.join(',') : payers,
    contractId: filter.contractId,
    ...mapUserIssueKeyToQueryParams(filter.userIssue)
  }

  return api.get<User[]>(url, {
    params
  })
}

const getCustomers = async (customerNumber?: string) => {
  try {
    const params = {
      customerNumber: customerNumber
    }
    const customers = await api.get<Customer[]>('/customers', {params})
    return customers.data.map((customer) => customer.customerId)
  } catch (error) {
    if ((error as AxiosError).response?.status === 404) {
      throw new Error(noResultsFromCustomerLookupLabel)
    }
    throw error
  }
}

const getPayers = async (payerNumber?: string) => {
  try {
    const params = {
      payerNumber: payerNumber
    }
    const payers = await api.get<Payer[]>('/payers', {params})
    return payers.data.map((payer) => payer.payerId)
  } catch (error) {
    if ((error as AxiosError).response?.status === 404) {
      throw new Error(noResultsFromPayerLookupLabel)
    }
    throw error
  }
}

const usersFetcher: QueryFunction<AxiosResponse<User[]> | null> = async ({queryKey}) => {
  const [base, {filter, loggedInUserId}] = queryKey as QueryKey

  try {
    const customers = filter.customerId && (await getCustomers(filter.customerId))
    const payers = filter.payerId && (await getPayers(filter.payerId))

    const users = await getUsers(
      `/${base}`,
      filter,
      loggedInUserId,
      customers && customers.length ? customers : filter.customerId,
      payers && payers.length ? payers : filter.payerId
    )
    return users
  } catch (e) {
    const error = e as AxiosError
    console.error(error)
    trackEvent('adminConsoleError', {
      product: 'adminConsole',
      date: new Date().toISOString(),
      errorCode: error.response?.status,
      component: 'useUsers.ts usersFetcher',
      endpoint: error.response?.config?.url
    })
    return null
  }
}

export const useUsers = (filter: UsersTableFilter, enabled: boolean) => {
  const loggedInUserId = useLoggedInUser()?.id
  return useQuery<AxiosResponse<User[]> | null, AxiosError, AxiosResponse<User[]>, QueryKey>(
    ['users', {filter, loggedInUserId}],
    usersFetcher,
    {
      retry: false,
      enabled
    }
  )
}
