import {useFeaturesCheckFetcher} from '@hconnect/common/components/FeaturesCheck'
import {trackEventWithBrowserProps} from '@hconnect/common/logging/Analytics'
import {
  AccountDropdown,
  CommonHeader,
  DefaultFooter,
  NavItem,
  useBreakPoints,
  useElementSize
} from '@hconnect/uikit'
import {Shell} from '@hconnect/uikit/src/lib2'
import {Box} from '@material-ui/core'
import {nanoid} from 'nanoid'
import {SnackbarProvider} from 'notistack'
import React, {useEffect, useRef} from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {Redirect, Route, useLocation} from 'react-router'

import {api, loginFlow} from './App.store'
import {useAdminPilotUser} from './common/hooks/useAdminPilotUser'
import {useUserRoles} from './common/hooks/useUserRoles'
import {HeaderLogo} from './components/HeaderLogo'
import {ErrorInfo} from './ErrorInfo'
import {useHeaderBrandings} from './hooks/useHeaderBrandings'
import {
  selectLoggedInUserPermissions,
  selectLoggedInUserProfile
} from './modules/LoggedInUser.selectors'
import {Announcements} from './pages/Announcements/Announcements'
import {AnnouncementCreate} from './pages/Announcements/Announcements.create'
import {Configurations} from './pages/Configurations/Configurations'
import {CreateUser} from './pages/CreateUser/CreateUser'
import {FeatureCreate} from './pages/FeaturesList/components/FeatureCreate'
import {FeaturesList} from './pages/FeaturesList/FeaturesList'
import {LegalDocuments} from './pages/LegalDocuments/LegalDocuments'
import {ManageUser} from './pages/ManageUser/ManageUser'
import {ManageUserPilot} from './pages/ManageUser/ManageUserPilot'
import {UsersList} from './pages/UsersList/UsersList'
import {Version} from './pages/Version'
import {PrivateRoute} from './PrivateRoute'

// eslint-disable-next-line complexity
export const App: React.FC = () => {
  const {t, i18n} = useTranslation()

  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  const sessionKeyRef = useRef<null | string>(null)
  const location = useLocation()

  const {data: features} = useFeaturesCheckFetcher(api)

  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)
  const permissions = useSelector(selectLoggedInUserPermissions)

  const {data: roles} = useUserRoles(loggedInUserProfile?.user_id)

  const {branding} = useHeaderBrandings(loggedInUserProfile?.defaultBranding)
  const canEditAnnouncements = Boolean(
    features &&
      features.find((feature) => feature.name === 'Announcements' && feature.enabled) &&
      loggedInUserProfile?.hasRoles.some((role) => ['GLOBAL_ADMIN', 'SUPER_ADMIN'].includes(role))
  )

  useEffect(() => {
    const defaultLocale = loggedInUserProfile?.defaultLocale
    if (defaultLocale) void i18n.changeLanguage(defaultLocale)
  }, [i18n, loggedInUserProfile])

  useEffect(() => {
    trackEventWithBrowserProps('adminPageview', {
      product: 'adminconsole',
      date: new Date().toISOString(),
      country: loggedInUserProfile?.country ?? '',
      role: loggedInUserProfile?.hasRoles,
      sessionKey: sessionKeyRef.current
    })
  }, [loggedInUserProfile, location])

  useEffect(() => {
    sessionKeyRef.current = nanoid()
  }, [loggedInUserProfile?.id])

  const canEditConfigurations = permissions.some(
    (permission) => permission.permissionType === 'CHANGE_CONFIGURATIONS'
  )

  const canOnlySeeUserAdminPilot = useAdminPilotUser()

  const canSeeFeatures = permissions.some(
    (permission) => permission.permissionType === 'VIEW_FEATURES'
  )

  const canEditFeatures =
    permissions.some((permission) => permission.permissionType === 'CHANGE_FEATURES') ||
    !!roles?.find(
      (role) =>
        role.roleType === 'GLOBAL_ADMIN' &&
        (role.dataScope?.['countryId'] === '*' || role.dataScope?.['areaId'] === '*')
    ) ||
    false

  const routes: NavItem[] = [
    {
      label: t('navigation.users'),
      url: '/users',
      dataTestId: 'nav-link-users'
    }
  ]

  if (canEditConfigurations) {
    routes.push({
      label: t('navigation.configurations'),
      url: '/configuration',
      dataTestId: 'nav-link-configurations'
    })
  }
  if (canEditAnnouncements) {
    routes.push({
      label: t('navigation.announcements'),
      url: '/announcements',
      dataTestId: 'nav-link-announcements'
    })
  }

  if (canSeeFeatures) {
    routes.push({
      label: t('navigation.features'),
      url: '/features',
      dataTestId: 'nav-link-features'
    })
  }

  const [ref, {width}] = useElementSize()
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <ErrorBoundary FallbackComponent={() => <ErrorInfo />}>
        <Shell
          compact={false}
          isResponsive
          boxed={false}
          onDark={false}
          showScrollToTop={false}
          stickyFooter={!isMobile}
          zIndex={1002}
          header={
            <div ref={ref} style={{width: '100%'}}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                {!isMobile && (
                  <HeaderLogo
                    logo={branding?.nationalLogoUrl}
                    isCustomerAdmin={canOnlySeeUserAdminPilot}
                  />
                )}
                <Box style={{width: isMobile ? 'auto' : '100%'}}>
                  <CommonHeader
                    appName="HConnect"
                    navItems={routes}
                    disableAppSelect
                    parentWidth={width}
                  />
                </Box>
                {isMobile && (
                  <HeaderLogo withoutBranding={true} isCustomerAdmin={canOnlySeeUserAdminPilot} />
                )}
                <AccountDropdown
                  profile={{
                    name: loggedInUserProfile?.name ?? '',
                    email: loggedInUserProfile?.eMail ?? ''
                  }}
                  userPage={
                    location.pathname.includes('useradmin') || !canOnlySeeUserAdminPilot
                      ? `/manage/${loggedInUserProfile?.id}`
                      : undefined
                  }
                  logoutButtonText={t('actionBar.logout')}
                  actions={{
                    logout: () => {
                      void loginFlow.startLogoutProcess()
                    },
                    selectLanguage: () => {}
                  }}
                />
              </Box>
            </div>
          }
          footer={<DefaultFooter style={{minHeight: isMobile ? '48px' : '64px'}} />}
        >
          <Route path="/users" exact render={() => <UsersList />} />
          <Route path="/useradmin" exact render={() => <UsersList />} />
          <Route path="/createUser" exact render={() => <CreateUser />} />
          <Route path="/manage/:userId" exact render={() => <ManageUser />} />
          <Route path="/managePilot/:userId" exact render={() => <ManageUserPilot />} />
          <PrivateRoute
            path="/announcements"
            exact
            allowed={canEditAnnouncements}
            render={() => <Announcements />}
          />
          <PrivateRoute
            allowed={canEditAnnouncements}
            path="/announcements/create"
            exact
            render={() => <AnnouncementCreate />}
          />
          <PrivateRoute
            allowed={canEditAnnouncements}
            path="/announcements/manage/:messageId"
            exact
            render={() => <AnnouncementCreate />}
          />
          <PrivateRoute
            path="/configuration"
            exact
            allowed={canEditConfigurations}
            render={() => <Configurations />}
          />
          <PrivateRoute
            path="/features"
            exact
            allowed={canSeeFeatures}
            render={() => <FeaturesList />}
          />
          <PrivateRoute
            allowed={canEditFeatures}
            path="/features/create"
            exact
            render={() => <FeatureCreate />}
          />
          <PrivateRoute
            allowed={canEditFeatures}
            path="/features/edit/:featureName"
            exact
            render={() => <FeatureCreate />}
          />
          <Route path="/legal" exact render={() => <LegalDocuments />} />
          <Route path="/version" exact render={() => <Version />} />
          <Route path="/" exact render={() => <Redirect to="/users" />} />
        </Shell>
      </ErrorBoundary>
    </SnackbarProvider>
  )
}
