import {Button, DialogActions, DialogContentText} from '@material-ui/core'
import {Dialog, DialogContent, DialogTitle} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export type DiscardDialogProps = {
  setShouldRedirect: (shouldRedirect: boolean) => void
  shouldOpenDiscardDialog: boolean
  setShouldOpenDiscardDialog: (shouldOpen: boolean) => void
}
export const DiscardDialog = ({
  setShouldRedirect,
  shouldOpenDiscardDialog,
  setShouldOpenDiscardDialog
}: DiscardDialogProps) => {
  const {t} = useTranslation()
  return (
    <Dialog open={shouldOpenDiscardDialog}>
      <DialogTitle>{t('announcements.discardDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{color: '#00374d'}}>
          {t('announcements.discardDialog.content')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShouldOpenDiscardDialog(false)} color="primary" variant="text">
          {t('announcements.discardDialog.continue')}
        </Button>
        <Button onClick={() => setShouldRedirect(true)} variant="text" style={{color: '#CA605F'}}>
          {t('announcements.discardDialog.goBack')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
