import React, {FunctionComponent, useReducer} from 'react'

import {useRolesConfigurationForUserId} from '../common/hooks/useRolesConfiguration'
import {RoleEditLayout} from '../layouts/Roles/RoleEditLayout'
import {
  CHANGE_ROLE_TYPE,
  DELETE_CUSTOMER,
  DELETE_PAYER,
  DELETE_PLANT,
  REPLACE_CUSTOMERS_AND_PROJECTS_SITES,
  groupedRoleAssignmentReducer,
  REMOVE_CUSTOMER_SCOPE,
  DELETE_COMMENT_CATEGORY
} from '../modules/GroupedRoleAssignmentReducer'
import {CustomersScopes, GroupedRoleAssignment} from '../modules/ManageUsers.selectors'

interface RoleEditContainerProps {
  groupedRoleAssignment: GroupedRoleAssignment
  updateRoleAssignments: (groupedRoleAssignment: GroupedRoleAssignment) => Promise<void>
  onDiscard(): void
}

export const RoleEditContainer: FunctionComponent<RoleEditContainerProps> = ({
  groupedRoleAssignment,
  updateRoleAssignments,
  onDiscard
}) => {
  const [roleState, dispatchRoleStateUpdate] = useReducer(
    groupedRoleAssignmentReducer,
    groupedRoleAssignment
  )

  const {roleOptions} = useRolesConfigurationForUserId()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSaveContractIds = (_contractIds: string[]) => {
    // TODO: implement this action if necessary.
    // dispatchRoleStateUpdate({
    //   type: ##
    //   payload: contractIds
    // })
  }

  const handleSaveCustomerId = (customersScopes: CustomersScopes[]) => {
    dispatchRoleStateUpdate({
      type: REPLACE_CUSTOMERS_AND_PROJECTS_SITES,
      payload: customersScopes
    })
  }

  const handleDeleteCustomer = (customersScopes: CustomersScopes) => {
    dispatchRoleStateUpdate({
      type: DELETE_CUSTOMER,
      payload: customersScopes.customerId
    })
  }

  const handleDeletePayer = (payerId: string) => {
    dispatchRoleStateUpdate({
      type: DELETE_PAYER,
      payload: payerId
    })
  }

  const handleDeletePlant = (plantId: string) => {
    dispatchRoleStateUpdate({
      type: DELETE_PLANT,
      payload: plantId
    })
  }

  const handleDeleteContract = (contractId: string) => {
    dispatchRoleStateUpdate({
      type: REMOVE_CUSTOMER_SCOPE,
      payload: {
        contractId
      }
    })
  }

  const handleRoleTypeChange = (value: any) => {
    dispatchRoleStateUpdate({
      type: CHANGE_ROLE_TYPE,
      payload: value
    })
  }

  const handleDeleteCommentCategory = (commentCategory: string) =>
    dispatchRoleStateUpdate({type: DELETE_COMMENT_CATEGORY, payload: commentCategory})

  return (
    <RoleEditLayout
      groupedRoleAssignment={roleState}
      roleOptions={roleOptions}
      dispatchRoleStateUpdate={dispatchRoleStateUpdate}
      updateRoleAssignments={updateRoleAssignments}
      handleDiscard={onDiscard}
      handleSaveCustomerId={handleSaveCustomerId}
      handleDeleteCustomer={handleDeleteCustomer}
      handleDeletePayer={handleDeletePayer}
      handleDeletePlant={handleDeletePlant}
      handleDeleteContract={handleDeleteContract}
      handleRoleTypeChange={handleRoleTypeChange}
      handleSaveContractIds={handleSaveContractIds}
      handleDeleteCommentCategory={handleDeleteCommentCategory}
    />
  )
}
