import {Typography} from '@hconnect/uikit'
import {Box, Skeleton} from '@mui/material'
import React from 'react'

import {DetailsFieldProps} from './DetailsField.types'
export const DetailsField: React.FC<DetailsFieldProps> = ({
  label,
  value,
  children,
  gutter,
  dataTestId,
  isLoading = false
}) => (
  <Box mb={gutter ? 4 : 0} display="flex" flexDirection="column">
    <Box>
      <Typography
        component="div"
        style={{textTransform: 'none', fontSize: '12px', color: '#54708C'}}
      >
        {label}
      </Typography>
    </Box>
    <Box>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Typography variant="body2" color="textPrimary" data-test-id={dataTestId} breakWords={true}>
          {value || children}
        </Typography>
      )}
    </Box>
  </Box>
)
