import {makeStyles} from 'tss-react/mui'

export const useTableStyles = makeStyles()((theme) => ({
  root: {
    border: 0,
    height: 'fit-content'
  },
  headRow: {
    height: 40
  },
  headCell: {
    height: 'auto'
  },
  rowSelectable: {
    cursor: 'pointer',
    '&:hover': {
      transition: 'background-color 0.3s ease-in-out',
      backgroundColor: '#F5F9FD'
    }
  },
  rowSelected: {
    backgroundColor: '#EBF3FC'
  },
  rowSelectedPinned: {
    background: '#EBF3FC'
  },
  rowNotSelectedPinned: {
    background: '#FFFFFF'
  },
  vertical: {
    color: 'rgb(0,0,0)',
    borderBottomWidth: 4
  },
  mobileHeadRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '40px',
    backgroundColor: '#f5f6f7',
    margin: '0 -8px',
    borderRadius: '4px',
    padding: '4px 8px'
  },
  tablePaginationToolbar: {
    display: 'flex',
    width: '100%',
    color: '#54708C',
    fontWeight: 500,

    [theme.breakpoints.down('sm')]: {
      '& .MuiTablePagination-selectLabel': {
        display: 'none'
      }
    }
  },
  tablePaginationActions: {
    '& .MuiButtonBase-root': {
      boxShadow: 'none'
    }
  },
  tablePaginationDisplayedRows: {
    marginLeft: 'auto'
  },
  tablePaginationSpacer: {
    display: 'none'
  },
  tablePaginationActionIconButton: {
    color: '#016AD4'
  },
  tablePaginationSelectIcon: {
    color: '#54708C'
  },
  tablePaginationSelect: {
    color: '#016AD4',
    fontWeight: 500,
    paddingRight: 0,
    marginTop: '4px',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginLeft: 0
    }
  },
  noVerticalScrollbar: {
    '&::-webkit-scrollbar:vertical': {
      display: 'none',
      height: 0,
      width: 0
    },
    '&::-webkit-scrollbar-thumb:vertical': {
      display: 'none'
    }
  },
  tableScrollButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: 0,
    zIndex: 10,
    minWidth: 0,
    width: '34px',
    border: 'none',
    outline: 'none',
    '&:hover': {
      border: 'none',
      outline: 'none',
      backgroundColor: '#F5F6F7'
    }
  },
  tableRowRightShadow: {
    position: 'relative',
    '&::after': {
      content: "' '",
      height: 'calc(100% + 1px)',
      position: 'absolute',
      top: 0,
      width: '10px',
      boxShadow: 'rgb(0 0 0 / 11%) 10px 0px 6px -6px inset',
      right: '-10px'
    }
  },
  tableRowLeftShadow: {
    position: 'relative',
    '&::before': {
      content: "' '",
      height: 'calc(100% + 1px)',
      position: 'absolute',
      top: 0,
      width: '10px',
      boxShadow: 'rgb(0 0 0 / 11%) -10px 0px 6px -6px inset',
      left: '-10px'
    }
  }
}))
