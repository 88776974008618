import {trackEvent} from '@hconnect/common/logging/Analytics'
import {LoadingButton} from '@hconnect/uikit'
import {
  Box,
  Button,
  CardActions,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  Typography
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import {Tooltip} from '@mui/material'
import {AxiosError} from 'axios'
import classNames from 'classnames'
import {format, parseISO} from 'date-fns'
import {useSnackbar} from 'notistack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useAdminPilotUser} from '../../common/hooks/useAdminPilotUser'
import {RoleCard} from '../../components/Role/RoleCard'
import {GroupedRoleAssignment, RoleAssignment} from '../../modules/ManageUsers.selectors'
import {deleteRoleAssignment} from '../../modules/Roles.actions'

import {CommentCategories} from './CommentCategories'
import {CustomersAndProjectsSites} from './CustomersScopes'
import {Payers} from './Payers'
import {Plants} from './Plants'
import {DataScope} from './role-view/DataScope'
import {DeleteDataScope} from './role-view/DeleteDataScope'
import {UnsupportedDataScope} from './role-view/UnsupportedDataScope'
import {useStyles} from './RoleViewLayout.styles'
import {prettyRoleName} from './utils'

interface Props {
  isInternal: boolean
  canEdit: boolean
  commonDataScopes: string[]
  unsupportedDataScopes: string[]
  groupedRoleAssignment: GroupedRoleAssignment
  roleAssignments: RoleAssignment[]
  getRoles: () => void
  setEditing: React.Dispatch<React.SetStateAction<boolean>>
}

export const RoleViewLayout: React.FC<Props> = ({
  isInternal,
  canEdit,
  commonDataScopes,
  unsupportedDataScopes,
  groupedRoleAssignment,
  getRoles,
  setEditing,
  roleAssignments
}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {enqueueSnackbar} = useSnackbar()
  const [showDeleteScopeDialog, setShowDeleteScopeDialog] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const isUserAdminPilot = useAdminPilotUser()

  const isBusinessOwner = groupedRoleAssignment.roleType === 'BUSINESS_OWNER'

  const hasRoleOrders = unsupportedDataScopes.includes('orderIds')

  const deleteButtonIsDisabled =
    (!hasRoleOrders && !canEdit) || (isUserAdminPilot && isBusinessOwner)

  const deleteRecord = async () => {
    try {
      setLoading(true)
      await deleteRoleAssignment(groupedRoleAssignment)
      getRoles()
    } catch (e) {
      const error = e as AxiosError
      console.error(error)
      trackEvent('adminConsoleError', {
        product: 'adminConsole',
        date: new Date().toISOString(),
        errorCode: error.response?.status,
        component: 'RoleViewLayout.tsx deleteRoleAssignment',
        endpoint: error.response?.config?.url
      })
      enqueueSnackbar(`Role could not be deleted. Reason: ${error.message}`, {variant: 'error'})
    }
    setLoading(false)
    setShowDeleteScopeDialog(false)
  }

  const deleteDialog = (
    <Dialog
      data-test-id="delete-role-dialog"
      open={showDeleteScopeDialog}
      onClose={() => setShowDeleteScopeDialog(false)}
    >
      <DialogTitle>{t('roleAssignment.deleteRoleTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{color: 'black'}}>
          {t(`roleAssignment.roleTypes.${groupedRoleAssignment.roleType}`)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowDeleteScopeDialog(false)} variant="text" color="primary">
          {t('roleAssignment.deleteScopeBox.cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => void deleteRecord()}
          variant="text"
          color="primary"
          data-test-id="button-delete-role-confirm"
        >
          {t('roleAssignment.deleteRole')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )

  return (
    <>
      {deleteDialog}
      <RoleCard data-test-id={`roleCard-${groupedRoleAssignment.groupId}`}>
        <CardContent className={classes.cardContent}>
          <div className={classes.header}>
            <div>
              <Typography color="textPrimary" noWrap className={classes.roleLabel}>
                {t(
                  `roleAssignment.roleTypes.${groupedRoleAssignment.roleType}`,
                  prettyRoleName(groupedRoleAssignment.roleType)
                )}
              </Typography>
              <Typography variant="caption" style={{whiteSpace: 'pre-line'}}>
                {t(`roleAssignment.roleTypesSubtitles.${groupedRoleAssignment.roleType}`, '')}
              </Typography>
            </div>
            <Box>
              <Chip
                classes={{
                  root: classNames([
                    isInternal ? classes.chipInternal : classes.chipExternal,
                    classes.myChip
                  ])
                }}
                label={t(`roleAssignment.${isInternal ? 'internal' : 'external'}`)}
                data-test-id={isInternal ? 'chip-label-internal' : 'chip-label-external'}
              />
              {groupedRoleAssignment.autoGenerated && (
                <Chip
                  classes={{
                    root: classNames([classes.chipAuto, classes.myChip])
                  }}
                  label={t('roleAssignment.auto')}
                  data-test-id={'chip-label-auto'}
                />
              )}
            </Box>
          </div>
          {groupedRoleAssignment.expiryDate && (
            <Tooltip title={t('roleAssignment.expirationDateTip')} arrow placement="bottom">
              <Typography
                color="textPrimary"
                variant="caption"
                noWrap
                className={classes.roleLabel}
              >
                {t('roleAssignment.expirationDate', {
                  date: format(parseISO(groupedRoleAssignment.expiryDate), 'dd/MM')
                })}
              </Typography>
            </Tooltip>
          )}
          <>
            {commonDataScopes
              .filter((dataScopeName) =>
                isUserAdminPilot
                  ? dataScopeName !== 'countryId' &&
                    dataScopeName !== 'orgUnitId' &&
                    dataScopeName !== 'businessLine'
                  : true
              )
              .map((dataScopeName) => (
                <DataScope
                  key={dataScopeName}
                  dataScopeName={dataScopeName}
                  groupedRoleAssignment={groupedRoleAssignment}
                />
              ))}
            {groupedRoleAssignment.variantDataScopes.customersScopes.length > 0 && (
              <InputLabel shrink variant="standard">
                {t('roleAssignment.customersAndSitesSectionHeader')}
              </InputLabel>
            )}
            <CustomersAndProjectsSites groupedRoleAssignment={groupedRoleAssignment} />
            {unsupportedDataScopes.map((dataScopeName) => {
              if (hasRoleOrders) {
                return (
                  <DeleteDataScope
                    key={dataScopeName}
                    dataScopeName={dataScopeName}
                    dataScopeValues={
                      groupedRoleAssignment.unsupportedDataScopes.orderIds as string[]
                    }
                    groupedRoleAssignment={groupedRoleAssignment}
                    roleAssignments={roleAssignments}
                  />
                )
              }
              return (
                <UnsupportedDataScope
                  key={dataScopeName}
                  dataScopeName={dataScopeName}
                  groupedRoleAssignment={groupedRoleAssignment}
                />
              )
            })}

            <Payers groupedRoleAssignment={groupedRoleAssignment} isEditing={false} />
            <Plants groupedRoleAssignment={groupedRoleAssignment} isEditing={false} />
            <CommentCategories groupedRoleAssignment={groupedRoleAssignment} isEditing={false} />
          </>
        </CardContent>

        {!hasRoleOrders && !canEdit && (
          <Typography color="textPrimary" className={classes.restrictionHint}>
            {t('roleAssignment.unsupportedDataScopesHint')}
          </Typography>
        )}

        <CardActions className={classes.cardActions}>
          <>
            <Button
              variant="text"
              color="primary"
              onClick={() => setShowDeleteScopeDialog(true)}
              data-test-id={`delete-role-button-${groupedRoleAssignment.groupId}`}
              className={classes.deleteButton}
              disabled={deleteButtonIsDisabled}
            >
              <DeleteIcon className={classes.icon} />
              {t('roleAssignment.deleteRole')}
            </Button>

            {!hasRoleOrders && (
              <Button
                disabled={!canEdit}
                type="button"
                data-test-id={`edit-role-button-${groupedRoleAssignment.groupId}`}
                onClick={() => {
                  setEditing(true)
                }}
              >
                <EditIcon className={classes.icon} />
                {t('roleAssignment.editRole')}
              </Button>
            )}
          </>
        </CardActions>
      </RoleCard>
    </>
  )
}
