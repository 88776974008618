import {Typography} from '@hconnect/uikit'
import {Box, Chip, Tooltip} from '@mui/material'
import classNames from 'classnames'
import upperFirst from 'lodash/upperFirst'
import React from 'react'

import {useStyles} from './Products.styles'
import {ProductsProps} from './Products.types'

export const Products = ({products, isDetails = false, showAll = false}: ProductsProps) => {
  const {classes} = useStyles()

  const handleProductColumns = () => {
    if (products.length > 1 && !isDetails) {
      return 'repeat(2, auto)'
    }

    if (isDetails) {
      return 'repeat(2,1fr)'
    }

    return 'max-content'
  }
  products = products.sort((a, b) => a.localeCompare(b))

  const renderProduct = (products) => {
    return products.map((product: string) => (
      <Chip
        data-test-id={`product-chip-${upperFirst(product)}`}
        classes={{
          root: classNames({
            [classes.tags]: true,
            [classes.tagMod1]: true
          })
        }}
        key={product}
        label={upperFirst(product)}
        size="small"
      />
    ))
  }

  const getToolTipText = (products) => {
    return products.map((product: string) => upperFirst(product)).join(', ')
  }

  if (!showAll && products.length > 2) {
    return (
      <Tooltip title={getToolTipText(products)} arrow placement="bottom">
        <Box
          style={{
            display: 'inline-grid',
            gridGap: '4px',
            gridTemplateColumns: handleProductColumns()
          }}
        >
          {renderProduct(products.slice(0, 2))}
          <Typography variant="body1" component="div">
            <Typography component="div">+{`${products.length - 2} more`}</Typography>
          </Typography>
        </Box>
      </Tooltip>
    )
  }
  return (
    <Box
      style={{
        display: 'inline-grid',
        gridGap: '4px',
        gridTemplateColumns: handleProductColumns()
      }}
    >
      {renderProduct(products)}
    </Box>
  )
}
