/* eslint-disable complexity */
import {
  Content,
  dateFormatter,
  Page,
  ResponsiveTable,
  TitleNav,
  Typography,
  useBreakPoints
} from '@hconnect/uikit'
import {Box, ClickAwayListener, Grid, IconButton, Paper} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {Button} from '@mui/material'
import {useMutation} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {isEmpty} from 'lodash'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import React, {CSSProperties, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'

import {api} from '../../App.store'
import {useCountriesConfiguration} from '../../common/hooks/useCountriesConfiguration'
import {DetailsField} from '../../components/DetailsField/DetailsField'
import {SectionHeader} from '../../components/SectionHeader/SectionHeader'

import {Announcement} from './Announcements'
import {useStyles} from './Announcements.styles'
import {AnnouncementSummaryListRows} from './AnnouncementSummaryListRows'
import {MessageTypes, ScheduleDays} from './common'
import {AnnouncementForm, AnnouncementFormData, IsActive} from './components'
import {DiscardDialog} from './components/dialogs/DiscardDialog'
import {useAnnouncementDetails} from './hooks/useAnnouncements'

const createAnnouncement = async (data: AnnouncementFormData) => {
  const response: AxiosResponse<Announcement> = await api.post('/announcements', data)
  return response.data
}
const updateAnnouncement = (messageId: string) => async (data: AnnouncementFormData) => {
  const response: AxiosResponse<Announcement> = await api.put(`/announcements/${messageId}`, data)
  return response.data
}

const deleteAnnouncement = (messageId?: string) => async () => {
  return messageId && (await api.delete(`/announcements/${messageId}`))
}

export type AnnouncementSummary = {
  country: string
  validFrom: string
  validTo: string
}

export const AnnouncementCreate: React.FC = () => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const {messageId} = useParams<{messageId: string}>()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const isTablet = ['xs', 'sm', 'md'].includes(screenSizes)
  const [showSummary, setShowSummary] = useState(false)
  const handleShowSummary = (showSum: boolean) => {
    setShowSummary(showSum)
  }

  const [shouldRedirect, setShouldRedirect] = useState(false)
  const [shouldOpenDiscardDialog, setShouldOpenDiscardDialog] = useState(false)

  const {data: countries, isFetching: isCountriesFetching} = useCountriesConfiguration()
  const {data: editAnnouncement, isFetching: isAnnouncementFetching} =
    useAnnouncementDetails(messageId)
  const {enqueueSnackbar} = useSnackbar()
  const createAnnouncementMutation = useMutation(createAnnouncement, {
    onSuccess: () => {
      enqueueSnackbar(t('announcements.form.successCreateMsg'), {
        anchorOrigin: {vertical: 'top', horizontal: 'center'},
        variant: 'success'
      })
      history.push('/announcements')
    },
    onError: () => {
      enqueueSnackbar(t('announcements.form.errorCreateMsg'), {
        anchorOrigin: {vertical: 'top', horizontal: 'center'},
        variant: 'error'
      })
    }
  })
  const updateAnnouncementMutation = useMutation(updateAnnouncement(messageId), {
    onSuccess: () => {
      enqueueSnackbar(t('announcements.form.successUpdateMsg'), {
        anchorOrigin: {vertical: 'top', horizontal: 'center'},
        variant: 'success'
      })
      history.push('/announcements')
    },
    onError: () => {
      enqueueSnackbar(t('announcements.form.errorUpdateMsg'), {
        anchorOrigin: {vertical: 'top', horizontal: 'center'},
        variant: 'error'
      })
    }
  })

  const deleteAnnouncementMutation = useMutation(deleteAnnouncement(editAnnouncement?.messageId), {
    onSuccess: () => {
      enqueueSnackbar(t('announcements.form.successDeleteMsg'), {
        anchorOrigin: {vertical: 'top', horizontal: 'center'},
        variant: 'success'
      })
      history.push('/announcements')
    },
    onError: () => {
      enqueueSnackbar(t('announcements.form.errorDeleteMsg'), {
        anchorOrigin: {vertical: 'top', horizontal: 'center'},
        variant: 'error'
      })
    }
  })

  const history = useHistory()
  const formMethods = useForm<AnnouncementFormData>({
    mode: 'all',
    defaultValues: {
      messageType: editAnnouncement?.messageType || MessageTypes.information,
      validFrom: editAnnouncement?.validFrom || moment().startOf('day').toISOString(true),
      validTo: editAnnouncement?.validTo || moment().endOf('day').toISOString(true),
      businessLines: editAnnouncement?.businessLines || [],
      products: editAnnouncement?.products || [],
      countries:
        editAnnouncement?.countries || (countries?.length === 1 ? [countries[0].countryCode] : []),
      message: editAnnouncement?.message || '',
      pillarType: editAnnouncement?.pillarType || 'hConnect',
      timeFrom: editAnnouncement?.validFrom || moment().add(3, 'minutes').toISOString(true),
      timeTo: editAnnouncement?.validTo || moment().endOf('day').toISOString(true)
    },
    reValidateMode: 'onBlur'
  })

  const loading = isCountriesFetching || isAnnouncementFetching

  useEffect(() => {
    if (messageId && editAnnouncement) {
      formMethods.reset({...editAnnouncement})
    }
  }, [messageId, editAnnouncement])

  useEffect(() => {
    if (shouldRedirect) {
      history.push('/announcements')
    }
  }, [shouldRedirect])

  const handleScheduled = (scheduled?: string) => {
    switch (scheduled) {
      case ScheduleDays.oneDay:
        return 1
      case ScheduleDays.threeDays:
        return 3
      case ScheduleDays.fiveDays:
        return 5
      case ScheduleDays.sevenDays:
        return 7
      default:
        return 0
    }
  }

  const handleSubmitForm = async () => {
    const {businessLines, countries: formCountries, ...formValues} = formMethods.getValues()
    if (editAnnouncement && messageId) {
      updateAnnouncementMutation.mutateAsync({
        ...formValues,
        products: Array.isArray(products) ? products : [products],
        businessLines: pillar === 'hProduce' ? ['RMC', 'CEM', 'AGG'] : businessLines,
        countries:
          pillar === 'hProduce' ? countries?.map((c) => c.countryCode) || [] : formCountries
      })
    } else {
      createAnnouncementMutation.mutateAsync({
        ...formValues,
        products: Array.isArray(products) ? products : [products],
        validFrom: moment(fromDate).subtract(handleScheduled(scheduled), 'day').toISOString(true),
        businessLines: pillar === 'hProduce' ? ['RMC', 'CEM', 'AGG'] : businessLines,
        countries:
          pillar === 'hProduce' ? countries?.map((c) => c.countryCode) || [] : formCountries
      })
    }
  }

  const scheduled = formMethods.watch('schedule')
  const message = formMethods.watch('message')
  const messageType = formMethods.watch('messageType')
  const pillar = formMethods.watch('pillarType')
  const products = formMethods.watch('products')
  const fromDate = formMethods.watch('validFrom')
  const toDate = formMethods.watch('validTo')

  const handleFormattedOffset = (localOffset: number, offset: number) => {
    const absoluteOffset = Math.abs(localOffset + offset)
    if (absoluteOffset > 1) {
      return `(${-(localOffset + offset)} ${t('announcements.summaryCountries.hours')})`
    }

    if (absoluteOffset === 1) {
      return `(${-(localOffset + offset)} ${t('announcements.summaryCountries.hour')})`
    }

    if (absoluteOffset === 0) {
      return ''
    }
  }

  const calculateLocalTime = (countryCode: string, originalTime: string) => {
    const offsetList = moment.tz.zonesForCountry(countryCode, true)
    const localOffset = moment().local().utcOffset() / 60
    return offsetList
      ?.map((offset) => ({
        cityName: offset.name.split('/')[1],
        offset: offset.offset / 60
      }))
      .filter(
        (value, index, self) =>
          self.map((mapItem) => mapItem.offset).indexOf(value.offset) === index
      )
      .map((offset) => ({
        cityName:
          countryCode === 'RU'
            ? t(`announcements.summaryCountries.russia.${offset.cityName.toLowerCase()}`)
            : offset.cityName,
        time:
          `${moment(originalTime)
            .subtract(localOffset + offset.offset, 'hour')
            .format('HH:mm')}` +
          '\n' +
          `${handleFormattedOffset(localOffset, offset.offset)} `
      }))
  }

  const summaryData = formMethods.watch('countries').map((countryCode) => {
    const country = countries?.find((country) => country.countryCode === countryCode)?.countryName
    return {
      country:
        country === 'Russia' ? t('announcements.summaryCountries.russia.countryName') : country,
      validFrom: calculateLocalTime(countryCode, fromDate as string),
      validTo: calculateLocalTime(countryCode, toDate as string)
    }
  })

  const flattenArray = (array) => {
    return array.reduce((acc, item) => {
      return acc.concat(
        item?.validFrom?.map((validFrom, index) => {
          return {
            country: `${item.country}` + '\n' + `(${validFrom.cityName})`,
            validFrom: validFrom.time,
            validTo: item.validTo[index].time
          }
        })
      )
    }, [])
  }

  const formatMessageType = (messageType: string) => {
    switch (messageType) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      case MessageTypes.maintenance:
        return t('announcements.maintenance')
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      case MessageTypes.warning:
        return t('announcements.warning')
      default:
        return t('announcements.information')
    }
  }

  const formatProducts = () => {
    return Array.isArray(products)
      ? products.map((product) =>
          pillar === 'hConnect'
            ? t(`announcements.form.hconnect.${product.toLowerCase()}`)
            : t(`announcements.form.hproduce.${product.toLowerCase()}`)
        )
      : t(`announcements.form.hconnect.${(products as string).toLowerCase()}`)
  }

  const handleDelete = () => {
    deleteAnnouncementMutation.mutateAsync()
  }

  const formatAnnouncementsTitle = () => {
    return editAnnouncement && messageId
      ? t('announcements.form.editAnnouncement')
      : t('announcements.form.newAnnouncement')
  }

  const columns = AnnouncementSummaryListRows()

  const summaryStyle: CSSProperties = isMobile
    ? {
        background: '#FFFFFF',
        color: '#000000',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: '85vh',
        overflowY: 'scroll',
        zIndex: 1001
      }
    : {
        position: !isTablet ? 'static' : 'absolute',
        right: 0,
        background: '#FFFFFF',
        borderLeft: !isTablet ? 'none' : '1px solid #E8E8E8',
        width: '50%',
        zIndex: 1001,
        height: !isTablet ? 'auto' : '100%',
        boxShadow: '0 3px 8px rgba(0, 0, 0, 0.05)',
        overflowY: 'scroll'
      }

  const CloseButton = ({onClose, disabled = false}) => {
    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center" height={80}>
        <div>
          <IconButton
            onClick={() => onClose()}
            style={{border: 'none', boxShadow: 'none'}}
            data-test-id="modal-close-button"
            disabled={disabled}
          >
            <Close fontSize="medium" />
          </IconButton>
        </div>
      </Box>
    )
  }

  const summarySection = (
    <Grid item lg={4}>
      <Paper elevation={isTablet ? 0 : 4} style={{height: '100%'}}>
        <Box data-test-id="form-right" style={{padding: '24px 36px'}}>
          <Box data-test-id="form-summary" mb={1}>
            <SectionHeader
              title={t('announcements.form.summary')}
              style={{marginTop: isMobile ? '2rem' : '5.75rem'}}
            />
          </Box>
          {editAnnouncement && (
            <Box style={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
              <IsActive
                validFrom={editAnnouncement.validFrom}
                validTo={editAnnouncement.validTo}
                t={t}
              />
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '24px',
                  width: '75%'
                }}
              >
                <DetailsField
                  label={t('announcements.createdDate')}
                  value={dateFormatter(editAnnouncement.createdOn)}
                  gutter
                  dataTestId="announcement-details-created-date-field"
                />
                <DetailsField
                  label={t('announcements.author')}
                  value={editAnnouncement.author}
                  gutter
                  dataTestId="announcement-details-author-field"
                />
              </Box>
            </Box>
          )}
          <Box style={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
            {products.length > 0 && toDate && fromDate && (
              <Typography>
                {t('announcements.summary', {
                  messageType: formatMessageType(messageType),
                  products: formatProducts(),
                  validFrom: moment(fromDate).format('DD-MM-YYYY'),
                  validTo: moment(toDate).format('DD-MM-YYYY')
                })}
                {messageType === (MessageTypes.maintenance as string)
                  ? t('announcements.summaryMaintenance', {scheduled: scheduled})
                  : ''}
              </Typography>
            )}
            <DetailsField
              label={t('announcements.message')}
              value={message}
              gutter
              dataTestId="announcement-details-author-field"
            />
            {pillar === 'hConnect' && (
              <ResponsiveTable
                columns={columns}
                rows={flattenArray(summaryData) || []}
                onSort={() => null}
                emptyMessage={t('announcements.summaryCountries.empty')}
                keyField="countryName"
                loading={loading}
              />
            )}
            <Box data-test-id="summary-action-field" className={classes.summaryActionsContainer}>
              {editAnnouncement && !formMethods.formState.isDirty ? (
                <Button
                  data-test-id="summary-action-delete-field-button"
                  startIcon={<DeleteOutlineIcon fontSize="small" />}
                  variant="outlined"
                  classes={{root: classes.discardButton, disabled: classes.discardButtonDisabled}}
                  onClick={() => handleDelete()}
                >
                  {t('announcements.form.delete')}
                </Button>
              ) : (
                <Button
                  data-test-id="summary-action-discard-field-button"
                  startIcon={<BackspaceOutlinedIcon fontSize="small" />}
                  variant="outlined"
                  disabled={!formMethods.formState.isDirty}
                  classes={{root: classes.discardButton, disabled: classes.discardButtonDisabled}}
                  onClick={() => setShouldOpenDiscardDialog(true)}
                >
                  {t('announcements.form.discard')}
                </Button>
              )}
              <Button
                data-test-id="summary-action-field-button"
                type="submit"
                variant="contained"
                startIcon={<ArrowForwardIcon style={{color: '#FFFFFF'}} fontSize="small" />}
                disabled={!formMethods.formState.isDirty || !isEmpty(formMethods.formState.errors)}
                classes={{root: classes.saveButton, disabled: classes.saveButtonDisabled}}
              >
                {t('announcements.form.saveAndSchedule')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  )

  return (
    <Content>
      <Page
        px={isMobile ? 2 : 6}
        boxed={false}
        className={classes.pageContent}
        title={!isTablet && <Box className={classes.pageTitle}>{formatAnnouncementsTitle()}</Box>}
        aboveTitle={
          <div className={classes.backButton}>
            <TitleNav
              title={isTablet ? formatAnnouncementsTitle() : t('announcements.goBack')}
              customTitle={
                !isTablet && (
                  <Box className={classes.pageNav}>
                    <ArrowBackIcon />
                    <Typography>{t('announcements.goBack')}</Typography>
                  </Box>
                )
              }
              onClick={() => history.goBack()}
              className={classes.pageAboveTitle}
            />
          </div>
        }
      >
        <form onSubmit={formMethods.handleSubmit(handleSubmitForm)}>
          <Grid container style={{columnGap: '16px', alignItems: 'stretch'}} wrap="nowrap">
            <Grid item lg={8} md={12} style={{position: 'relative'}}>
              <Paper elevation={4} style={{position: 'relative'}}>
                {isTablet && (
                  <Box
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                      backgroundColor: '#FFFFFF',
                      opacity: showSummary ? 0.8 : 0,
                      zIndex: showSummary ? 1000 : 0
                    }}
                  />
                )}

                {isTablet && showSummary && (
                  <ClickAwayListener onClickAway={() => handleShowSummary(false)}>
                    <Box style={summaryStyle}>
                      <CloseButton onClose={() => handleShowSummary(false)} />
                      {summarySection}
                    </Box>
                  </ClickAwayListener>
                )}
                <Box data-test-id="form-left" style={{padding: '24px 36px'}}>
                  <Box data-test-id="form-title" mb={1}>
                    <SectionHeader
                      title={t('announcements.form.details')}
                      style={{marginTop: isMobile ? '2rem' : '5.75rem'}}
                    />
                  </Box>
                  <AnnouncementForm
                    formMethods={formMethods}
                    countries={countries}
                    setShowSummary={setShowSummary}
                    editAnnouncement={editAnnouncement}
                  />
                </Box>
                <DiscardDialog
                  setShouldRedirect={setShouldRedirect}
                  shouldOpenDiscardDialog={shouldOpenDiscardDialog}
                  setShouldOpenDiscardDialog={setShouldOpenDiscardDialog}
                />
              </Paper>
            </Grid>
            {!isTablet && summarySection}
          </Grid>
        </form>
      </Page>
    </Content>
  )
}
