import {Logos} from '@hconnect/uikit'
import LogoMHC from '@hconnect/uikit/src/common/assets/LogoMHC.svg'
import {Box, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import {Link} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: '1 0 auto',
    cursor: 'pointer',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flex: '0 1 auto'
    }
  },
  nonVisible: {
    opacity: 0
  },
  containerSmall: {
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: theme.spacing(6),
    padding: '8px 0',
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    }
  },
  logoSmall: {
    height: theme.spacing(2.75),
    flex: '1',
    padding: `0 ${theme.spacing(1)}px 0 ${theme.spacing(0.75)}px `
  },
  logoSmallFontFace: {
    height: theme.spacing(2.75),
    flex: '1',
    padding: `0 ${theme.spacing(3)}px 0 ${theme.spacing(1)}px `,
    borderRight: '1px solid rgba(0, 0, 0, 0.06)'
  },
  logoSmallLH: {
    height: theme.spacing(4),
    flex: '1',
    padding: `2px ${theme.spacing(0.75)}px 0 ${theme.spacing(3)}px`
  },
  logos: {
    margin: `0 ${theme.spacing(2)}px 0 0`,
    fontSize: '1.145em'
  },
  withoutBrandingLogo: {
    fontSize: '1.145em',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}))

type HeaderLogoProps = {
  isCustomerAdmin: boolean
  withoutBranding?: boolean
  logo?: string
}
export const HeaderLogo = ({withoutBranding, logo, isCustomerAdmin}: HeaderLogoProps) => {
  const classes = useStyles()
  const redirectPath = window.location.pathname === '/useradmin' ? '/useradmin' : '/'

  return (
    <Link
      to="/"
      onClick={() => (window.location.href = redirectPath)}
      data-test-id="hconnect-logo"
      style={{display: 'flex', textDecoration: 'none'}}
    >
      <Logos className={withoutBranding ? classes.withoutBrandingLogo : classes.logos}>
        <img
          src={LogoMHC}
          style={{maxWidth: '130px', height: '55px'}}
          alt="HConnect"
          data-test-id="logo-hconnect"
        />
        {logo && !withoutBranding && isCustomerAdmin && (
          <img
            style={{
              maxWidth: '156px',
              height: '3em',
              fontSize: '1.145em'
            }}
            src={logo}
            data-test-id="logo-national"
            alt={'alt'}
          />
        )}
        {(!isCustomerAdmin || !logo) && !withoutBranding && (
          <Box style={{display: 'flex', alignItems: 'end', height: '100%'}}>
            <Typography
              style={{
                fontSize: '16px',
                lineHeight: '16px',
                padding: '0',
                whiteSpace: 'nowrap',
                height: '26px',
                color: 'rgba(0, 0, 0, 0.87)'
              }}
            >
              Admin Console
            </Typography>
          </Box>
        )}
      </Logos>
    </Link>
  )
}
