import filter from 'lodash/filter'
import flatMap from 'lodash/flatMap'
import isEqual from 'lodash/isEqual'
import iteratee from 'lodash/iteratee'
import React, {useEffect, useState} from 'react'

import {GridValueRowModel, KeyFieldType, TableRowType} from '../types'

export const useSelection = <R extends GridValueRowModel>(
  rows: TableRowType<R>,
  allRowsSelected = false,
  setAllRowsSelected: React.Dispatch<React.SetStateAction<boolean>>,
  keyField: KeyFieldType,
  isRowSelectable?: (row: R) => boolean
): [Set<string>, React.Dispatch<React.SetStateAction<Set<string>>>] => {
  const emptySet = new Set<KeyFieldType>()
  const [rowsSelected, setRowsSelected] = useState<Set<KeyFieldType>>(emptySet)
  const filteredRows = isRowSelectable ? filter(rows, (row) => isRowSelectable(row)) : rows
  const possibleIds = new Set(flatMap(filteredRows, iteratee(keyField)))

  useEffect(() => {
    if (allRowsSelected) {
      // @ts-ignore
      setRowsSelected(possibleIds)
    }
    if (possibleIds.size > 0 && isEqual(possibleIds, rowsSelected)) {
      setAllRowsSelected(true)
    } else {
      setAllRowsSelected(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRowsSelected])

  useEffect(() => {
    if (possibleIds.size > 0 && isEqual(possibleIds, rowsSelected)) {
      setAllRowsSelected(true)
    } else {
      setAllRowsSelected(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsSelected])

  useEffect(() => {
    if (allRowsSelected) {
      // @ts-ignore
      setRowsSelected(possibleIds)
    } else {
      // @ts-ignore
      const ids = new Set([...rowsSelected].filter((x) => possibleIds.has(x))) // set intersection
      setRowsSelected(ids)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  return [rowsSelected, setRowsSelected]
}
