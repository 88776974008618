import {Country} from '@hconnect/apiclient'
import {flagPathByCountryCode} from '@hconnect/uikit'
import {TextField} from '@material-ui/core'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {CountriesDropdown} from '../../CountriesDropdown/CountriesDropdown'
import {UserInformationInputSkeleton} from '../Skeleton/UserInformationInputSkeleton'

import {UserInformationProps} from './types'
import {useStyles} from './UserInput.styles'

type UserCountryInputProps = UserInformationProps & {
  countriesIds: string[]
  selectedCountry?: Country
}

export const UserCountryInput = ({
  readonlyMode,
  formMethods,
  countriesIds,
  isLoading,
  selectedCountry
}: UserCountryInputProps) => {
  const {t} = useTranslation()
  const errors = formMethods.formState.errors
  const touched = formMethods.formState.touchedFields
  const classes = useStyles()
  if (isLoading) {
    return <UserInformationInputSkeleton />
  }

  const countriesData = window.intlTelInputGlobals.getCountryData()

  const countryDataFilter = (countryId: string) =>
    countryId === 'MOCK'
      ? {name: 'MOCK', iso2: 'MOCK', dialCode: 'MOCK'}
      : countriesData.find((countryData) => countryData.iso2.toUpperCase() === countryId)

  const formattedCountryData = countriesIds
    .filter((countryId) => countryDataFilter(countryId))
    .map((countryId) => {
      return {
        label: countryDataFilter(countryId)?.name ?? '',
        value: countryDataFilter(countryId)?.iso2.toUpperCase() ?? ''
      }
    })
    .sort((current, next) => {
      if (current.label < next.label) {
        return -1
      }
      if (current.label > next.label) {
        return 1
      }
      return 0
    })

  return (
    <>
      {readonlyMode && selectedCountry ? (
        <TextField
          label={t('userInformationForm.country')}
          value={
            formattedCountryData.find(
              (countryData) => countryData.value === selectedCountry?.countryCode.toUpperCase()
            )?.label || selectedCountry?.countryName
          }
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            ...(selectedCountry?.countryCode !== 'MOCK' && {
              startAdornment: (
                <img
                  src={flagPathByCountryCode(selectedCountry?.countryCode)}
                  alt={`${selectedCountry?.countryCode}-flag`}
                  style={{marginRight: '12px'}}
                />
              )
            })
          }}
          InputLabelProps={{shrink: true}}
          margin="normal"
          fullWidth
        />
      ) : (
        countriesIds.length > 1 && (
          <Controller
            name="country"
            control={formMethods.control}
            render={({field}) => (
              <CountriesDropdown
                {...field}
                value={field.value || '-'}
                data-test-id={`userInformationForm-${field.name}`}
                select={!readonlyMode}
                className={classes.textField}
                fullWidth
                label={t('userInformationForm.country')}
                onChange={(e) => {
                  formMethods.setValue('country', e.target.value, {
                    shouldValidate: true
                  })
                  formMethods.setValue('defaultBranding', '', {
                    shouldValidate: true
                  })
                  formMethods.setValue('primaryOrganisationalUnit', '', {
                    shouldValidate: true
                  })
                  formMethods.setValue('marketId', '', {
                    shouldValidate: true
                  })
                  formMethods.setValue('defaultLocale', '', {
                    shouldValidate: true
                  })
                }}
                SelectProps={{
                  MenuProps: {
                    style: {
                      height: '400px'
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  }
                }}
                margin="normal"
                error={touched.country && !!errors.country}
                helperText={errors.country?.message}
                InputProps={{
                  readOnly: readonlyMode,
                  disableUnderline: readonlyMode
                }}
                countriesList={formattedCountryData}
                defaultSelectTitle={t('userInformationForm.select')}
              />
            )}
          />
        )
      )}
    </>
  )
}
