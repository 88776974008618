import {Country} from '@hconnect/apiclient'
import {Typography} from '@hconnect/uikit'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import {Box, Checkbox, CheckboxProps, Chip, Skeleton, Tooltip} from '@mui/material'
import classNames from 'classnames'
import clsx from 'clsx'
import {TFunction} from 'i18next'
import upperFirst from 'lodash/upperFirst'
import moment from 'moment'
import React from 'react'

import {countryDataByCountryCode} from '../../../common/utils'
import {Announcement} from '../Announcements'
import {useStyles as useAnnouncementStyles} from '../Announcements.styles'
import {useStyles} from '../AnnouncementsListRows.styles'

interface PillarProps {
  pillarType: string
}

export const Pillar: React.FC<PillarProps> = ({pillarType}) => {
  const {classes} = useStyles()
  return (
    <Box display="flex">
      <Chip
        classes={{
          root: classNames({
            [classes.tags]: true,
            [classes.tagMod1]: true
          })
        }}
        key={pillarType}
        label={upperFirst(pillarType)}
        size="small"
      />
    </Box>
  )
}

interface BusinessLinesProps {
  businessLines: ('CEM' | 'AGG' | 'RMC')[]
  t: TFunction
}
export const BusinessLines: React.FC<BusinessLinesProps> = ({businessLines, t}) => {
  const businessLinesTranslated = businessLines.map((businessLine) =>
    t(`announcements.${businessLine}`)
  )
  const businessLinesPrinting = businessLinesTranslated.join(', ')
  return (
    <Typography style={{whiteSpace: 'nowrap', fontSize: '14px'}}>
      {businessLines.length === 3 ? t('announcements.all') : businessLinesPrinting}
    </Typography>
  )
}

interface IsActiveProps {
  validFrom: string | Date
  validTo: string | Date
  t: TFunction
  small?: boolean
  isLoading?: boolean
}
export const IsActive: React.FC<IsActiveProps> = ({
  validFrom,
  validTo,
  t,
  small = false,
  isLoading = false
}) => {
  const {classes} = useStyles()
  const now = moment()
  const active = Boolean(now.diff(moment(validFrom)) >= 0 && now.diff(moment(validTo)) <= 0)
  const scheduled = Boolean(
    now.diff(moment(validFrom)) < 0 && moment(validFrom).diff(moment(validTo)) <= 0
  )
  return (
    <Box display="flex" alignItems="center">
      {!isLoading && (
        <Box
          fontSize={small ? 8 : 12}
          className={active ? classes.hourGlassActive : classes.hourGlassExpired}
        >
          <HourglassTopIcon fontSize="inherit" />
        </Box>
      )}
      <Box mx={0.5} />
      <Box>
        {isLoading ? (
          <Skeleton width={80} />
        ) : (
          <Typography style={{fontSize: '14px', color: active ? '#009D93' : '#00274D'}}>
            {active
              ? t('announcements.active')
              : scheduled
              ? t('announcements.scheduled')
              : t('announcements.expired')}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
interface CountriesType {
  announcement: Announcement
  countries?: Country[]
  showAll?: boolean
}
export const Countries: React.FC<CountriesType> = ({announcement, countries, showAll = false}) => {
  const {countries: countryCodes} = announcement
  const countriesData = window.intlTelInputGlobals.getCountryData()

  const countryDataFilter = (countryId?: string | null) =>
    countryDataByCountryCode(countriesData, countryId)

  let countryMapping = countries
    ? countryCodes.map((countryCode) => countryDataFilter(countryCode)?.name || countryCode)
    : countryCodes
  countryMapping = countryMapping.sort((a, b) => a.localeCompare(b))

  if (!showAll && announcement.countries.length > 4) {
    return (
      <Tooltip title={countryMapping.join(', ')} arrow placement="bottom">
        <Box display="flex" flexWrap="nowrap">
          <Typography component="div" style={{fontSize: '14px'}}>
            {countryMapping.slice(0, 3).join(', ')}
            <Typography component="div" style={{fontSize: '14px'}}>
              +{`${announcement.countries.length - 3} more`}
            </Typography>
          </Typography>
        </Box>
      </Tooltip>
    )
  }
  return (
    <Box display="flex" flexWrap="wrap">
      <Typography component="div" style={{fontSize: '14px'}}>
        {countryMapping.join(', ')}
      </Typography>
    </Box>
  )
}
interface CheckboxFormType extends CheckboxProps {
  dataTestId: string
}

export const CheckboxForm: React.FC<CheckboxFormType> = ({dataTestId, ...props}) => {
  const {classes} = useAnnouncementStyles()
  return (
    <Checkbox
      data-test-id={dataTestId}
      disableRipple
      color="primary"
      icon={<span className={clsx(classes.checkboxIcon, classes.uncheckedBorder)} />}
      checkedIcon={<span className={clsx(classes.checkboxIcon, classes.checkboxCheckedIcon)} />}
      classes={{indeterminate: classes.checkboxIndeterminateIcon}}
      {...props}
    />
  )
}
