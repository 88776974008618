import {
  Customer,
  getCustomerById,
  getCustomersByNumber,
  getCustomers,
  getCustomersByName,
  getCustomersWithoutNumber
} from '@hconnect/apiclient'
import {QueryFunction, useQuery} from '@tanstack/react-query'

import {api} from '../App.store'

type CustomerParam = {
  customerId: string
}

type CustomerNumberParam = {
  customerNumber: string
  countryId: string
  orgUnitId: string
  businessLine: string
}

type CustomerNameParam = {
  customerName: string
  countryId: string
  orgUnitId: string
  businessLine: string
}

const fetchCustomerByNumber: QueryFunction<Customer[], [string, CustomerNumberParam]> = ({
  queryKey
}: {
  queryKey: [string, CustomerNumberParam]
}): Promise<Customer[]> => {
  const [, {customerNumber, countryId, orgUnitId, businessLine}] = queryKey

  return getCustomersByNumber(api)(customerNumber, {countryId, orgUnitId, businessLine})
}

const fetchCustomerWithoutNumber: QueryFunction<
  Customer[],
  [string, Omit<CustomerNumberParam, 'customerNumber'>]
> = ({
  queryKey
}: {
  queryKey: [string, Omit<CustomerNumberParam, 'customerNumber'>]
}): Promise<Customer[]> => {
  const [, {countryId, orgUnitId, businessLine}] = queryKey

  return getCustomersWithoutNumber(api)({countryId, orgUnitId, businessLine})
}

const fetcher: QueryFunction<Customer, [string, CustomerParam]> = ({
  queryKey
}: {
  queryKey: [string, CustomerParam]
}): Promise<Customer> => {
  const [, {customerId}] = queryKey

  return getCustomerById(api)(customerId)
}
export const useCustomer = ({customerId}: CustomerParam, refetchOnMount?: boolean) => {
  return useQuery(['customers', {customerId}], fetcher, {retry: false, refetchOnMount})
}

export const useCustomerByNumber = (
  customerNumber: string,
  countryId: string,
  orgUnitId: string,
  businessLine: string,
  enabled?: boolean
) => {
  return useQuery(
    ['customers', {customerNumber, countryId, orgUnitId, businessLine}],
    fetchCustomerByNumber,
    {
      retry: false,
      enabled
    }
  )
}

export const useCustomerWithoutNumber = (
  countryId: string,
  orgUnitId: string,
  businessLine: string,
  enabled?: boolean
) => {
  return useQuery(['customers', {countryId, orgUnitId, businessLine}], fetchCustomerWithoutNumber, {
    retry: false,
    enabled
  })
}

const fetchCustomers = ({queryKey}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [endpoint] = queryKey
  return getCustomers(api)()
}

export const useCustomers = (enabled?: boolean) => {
  return useQuery(['customers'], fetchCustomers, {
    enabled
  })
}

const fetchCustomerByName: QueryFunction<Customer[], [string, CustomerNameParam]> = ({
  queryKey
}: {
  queryKey: [string, CustomerNameParam]
}): Promise<Customer[]> => {
  const [, {customerName, countryId, orgUnitId, businessLine}] = queryKey

  return getCustomersByName(api)(customerName, {
    countryId,
    orgUnitId,
    businessLine
  })
}

export const useCustomerByName = (
  customerName: string,
  countryId: string,
  orgUnitId: string,
  businessLine: string,
  enabled?: boolean
) => {
  return useQuery(
    ['customers', {customerName, countryId, orgUnitId, businessLine}],
    fetchCustomerByName,
    {
      retry: false,
      enabled
    }
  )
}
