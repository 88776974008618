import {Content, Page, TitleNav, Typography, useBreakPoints} from '@hconnect/uikit'
import {Add, Close, ArrowForward, BackspaceOutlined, ArrowBackIos} from '@mui/icons-material'
import {Box, Button, ClickAwayListener, Grid, IconButton, Paper} from '@mui/material'
import {isEmpty} from 'lodash'
import React, {CSSProperties, useEffect, useState} from 'react'
import {useFieldArray, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'

import {SectionHeader} from '../../../components/SectionHeader/SectionHeader'
import {DiscardDialog} from '../../Announcements/components/dialogs/DiscardDialog'
import {DeleteFeatureDialog} from '../dialogs/DeleteFeatureDialog'
import {useFeatureDetails} from '../hooks/useFeatureDetails'
import {useFeaturesList} from '../hooks/useFeaturesList'

import {FeatureCreateForm} from './FeatureCreate.form'
import {useStyles} from './FeatureCreate.styles'

export type FeatureFormData = {
  featureName: string
  featureDescription: string
  featureTags: string[]
  featureConstraints: FeatureFormConstraint[]
}

export type FeatureFormConstraint = {
  type: string
  enabled: boolean
  dataScope: string
}
// eslint-disable-next-line complexity
export const FeatureCreate = () => {
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const isTablet = ['xs', 'sm', 'md'].includes(screenSizes)
  const {t} = useTranslation()
  const history = useHistory()
  const {featureName} = useParams<{featureName?: string}>()

  const {classes} = useStyles()
  const [selectedTags, setSelectedTags] = useState<string[]>([])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {data: editFeature, isLoading: isEditLoading} = useFeatureDetails(featureName)
  const [deleteFeatureDialogData, setDeleteFeatureDialogData] = useState<string | undefined>()

  useEffect(() => {
    if (editFeature && featureName) {
      formMethods.reset({
        featureName: editFeature.name,
        featureDescription: editFeature.description,
        featureTags: editFeature.tags,
        featureConstraints: editFeature.constraints as FeatureFormConstraint[]
      })
      setSelectedTags(editFeature.tags)
    }
  }, [editFeature])

  const defaultFeatureForm = {
    featureName: '',
    featureDescription: '',
    featureTags: [''],
    featureConstraints: [{dataScope: '', type: '', enabled: false}]
  }

  const formatFeaturesTitle = () => {
    return editFeature && featureName
      ? t('features.form.editFeature')
      : t('features.form.newFeature')
  }
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const [shouldOpenDiscardDialog, setShouldOpenDiscardDialog] = useState(false)
  const [selectedInput, setSelectedInput] = useState<string>('')
  const [shouldValidate, setShouldValidate] = useState(false)
  const [showSummary, setShowSummary] = useState(false)
  const handleShowSummary = (showSum: boolean) => {
    setShowSummary(showSum)
  }

  useEffect(() => {
    if (shouldRedirect) {
      history.push('/features')
    }
  }, [shouldRedirect])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {createFeature, updateFeature, isLoading} = useFeaturesList()

  const formMethods = useForm<FeatureFormData>({
    defaultValues: defaultFeatureForm,
    mode: 'onChange'
  })

  const validateFeatureTags = () => {
    if (selectedTags.length > 0) {
      formMethods.clearErrors('featureTags')
      formMethods.setValue('featureTags', selectedTags)
    }

    if (selectedTags.length < 1) {
      formMethods.setError('featureTags', {type: 'custom', message: 'custom error message'})
    }
  }

  useEffect(() => {
    if (shouldValidate) {
      validateFeatureTags()
    }

    setShouldValidate(true)
  }, [selectedTags])

  const handleRequestDownload = (data: FeatureFormData) => {
    validateFeatureTags()
    const params = {
      name: featureName?.replace(/ /g, '').trim() || data.featureName.replace(/ /g, '').trim(),
      description: data.featureDescription,
      tags: data.featureTags,
      constraints: data.featureConstraints
    }

    editFeature ? updateFeature(params) : createFeature(params)
  }

  const handleDeleteFeature = (featureName?: string) => {
    setDeleteFeatureDialogData(featureName)
  }

  const {fields, append, remove} = useFieldArray({
    name: 'featureConstraints',
    control: formMethods.control
  })

  const tags = [
    'hub',
    'adminconsole',
    'invite',
    'finance',
    'payments',
    'invoices',
    'orderExpand',
    'deliveries',
    'data platform',
    'onsite',
    'orders',
    'legal'
  ]

  const CloseButton = ({onClose, disabled = false}) => {
    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center" height={80}>
        <div>
          <IconButton
            onClick={() => onClose()}
            style={{border: 'none', boxShadow: 'none'}}
            data-test-id="modal-close-button"
            disabled={disabled}
          >
            <Close fontSize="medium" />
          </IconButton>
        </div>
      </Box>
    )
  }

  const summaryStyle: CSSProperties = isMobile
    ? {
        background: '#FFFFFF',
        color: '#000000',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: '85vh',
        overflowY: 'scroll',
        zIndex: 1001
      }
    : {
        position: !isTablet ? 'static' : 'absolute',
        right: 0,
        background: '#FFFFFF',
        borderLeft: !isTablet ? 'none' : '1px solid #E8E8E8',
        width: '50%',
        zIndex: 1001,
        height: !isTablet ? 'auto' : '100%',
        boxShadow: '0 3px 8px rgba(0, 0, 0, 0.05)',
        overflowY: 'scroll'
      }

  const featureNameValue = formMethods.watch('featureName')

  const summarySection = (
    <Grid item lg={4}>
      <Paper elevation={isTablet ? 0 : 4} style={{height: '100%'}}>
        <Box data-test-id="form-right" style={{padding: '24px 36px'}}>
          <Box data-test-id="form-summary" mb={1}>
            <SectionHeader title={t('features.form.summary')} />
            <Box style={{display: 'flex', flexDirection: 'column', gap: '24px', marginTop: '24px'}}>
              {featureNameValue && (
                <Typography>
                  {t('features.form.summaryDesc', {
                    featureName: featureNameValue
                  })}
                </Typography>
              )}
              <Box data-test-id="summary-action-field" className={classes.summaryActionsContainer}>
                <Button
                  data-test-id="summary-action-discard-field-button"
                  startIcon={<BackspaceOutlined fontSize="small" />}
                  variant="outlined"
                  disabled={!formMethods.formState.isDirty}
                  classes={{root: classes.discardButton, disabled: classes.discardButtonDisabled}}
                  onClick={() => setShouldOpenDiscardDialog(true)}
                >
                  {t('features.form.discard')}
                </Button>
                <Button
                  data-test-id="summary-action-field-button"
                  type="submit"
                  variant="contained"
                  startIcon={<ArrowForward style={{color: '#FFFFFF'}} fontSize="small" />}
                  disabled={!isEmpty(formMethods.formState.errors)}
                  classes={{root: classes.saveButton, disabled: classes.saveButtonDisabled}}
                >
                  {t('features.form.submit')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  )

  return (
    <Content>
      <Page
        px={isMobile ? 2 : 6}
        boxed={false}
        className={classes.pageContent}
        title={
          !isTablet && (
            <Box
              className={classes.pageTitle}
              {...(!editFeature && {style: {marginTop: '16px', marginBottom: '16px'}})}
            >
              {formatFeaturesTitle()}
            </Box>
          )
        }
        aboveTitle={
          <Box className={classes.backButton}>
            <TitleNav
              title={isTablet ? formatFeaturesTitle() : t('features.goBack')}
              customTitle={
                !isTablet && (
                  <Box className={classes.pageNav}>
                    <ArrowBackIos />
                    <Typography>{t('features.goBack')}</Typography>
                  </Box>
                )
              }
              onClick={() => history.goBack()}
              className={classes.pageAboveTitle}
            />
            {isTablet && editFeature && (
              <Button
                variant="contained"
                data-test-id="delete-feature-button"
                onClick={() => handleDeleteFeature(featureName)}
                className={classes.deleteButton}
              >
                <Add className={classes.addIcon} />
                {t('features.form.delete')}
              </Button>
            )}
          </Box>
        }
        headerActionContent={
          !isTablet &&
          editFeature && (
            <Button
              variant="contained"
              data-test-id="delete-feature-button"
              onClick={() => handleDeleteFeature(featureName)}
              className={classes.deleteButton}
            >
              <Add className={classes.addIcon} />
              {t('features.form.delete')}
            </Button>
          )
        }
      >
        <form
          onSubmit={formMethods.handleSubmit(handleRequestDownload)}
          className={classes.disableAutofill}
        >
          <Grid container style={{columnGap: '16px', alignItems: 'stretch'}} wrap="nowrap">
            <Grid item lg={8} md={12} style={{position: 'relative', width: '100%'}}>
              <Paper elevation={4} style={{position: 'relative'}}>
                {isTablet && (
                  <Box
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                      backgroundColor: '#FFFFFF',
                      opacity: showSummary ? 0.8 : 0,
                      zIndex: showSummary ? 1000 : 0
                    }}
                  />
                )}

                {isTablet && showSummary && (
                  <ClickAwayListener onClickAway={() => handleShowSummary(false)}>
                    <Box style={summaryStyle}>
                      <CloseButton onClose={() => handleShowSummary(false)} />
                      {summarySection}
                    </Box>
                  </ClickAwayListener>
                )}
                <Box data-test-id="form-left" style={{padding: '24px 36px'}}>
                  <Box data-test-id="form-title" style={{marginBottom: '32px'}}>
                    <SectionHeader title={t('features.form.details')} />
                  </Box>
                  <Box style={{marginBottom: '20px'}}>
                    <Typography style={{fontSize: '18px', fontWeight: 600}}>
                      {t('features.form.description')}
                    </Typography>
                  </Box>
                  <FeatureCreateForm
                    formMethods={formMethods}
                    fields={fields}
                    append={append}
                    remove={remove}
                    tags={tags}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    selectedInput={selectedInput}
                    setSelectedInput={setSelectedInput}
                    setShowSummary={setShowSummary}
                  />
                </Box>
                <DiscardDialog
                  setShouldRedirect={setShouldRedirect}
                  shouldOpenDiscardDialog={shouldOpenDiscardDialog}
                  setShouldOpenDiscardDialog={setShouldOpenDiscardDialog}
                />
                {deleteFeatureDialogData && (
                  <DeleteFeatureDialog
                    deleteFeatureDialogData={deleteFeatureDialogData}
                    setDeleteFeatureDialogData={setDeleteFeatureDialogData}
                  />
                )}
              </Paper>
            </Grid>
            {!isTablet && summarySection}
          </Grid>
        </form>
      </Page>
    </Content>
  )
}
