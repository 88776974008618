import {TextField} from '@material-ui/core'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {UserInformationInputSkeleton} from '../Skeleton/UserInformationInputSkeleton'
import {handleNoSpecialCharacters} from '../utils/handleNoSpecialCharacters'

import {UserInformationProps} from './types'
import {useStyles} from './UserInput.styles'

export const UserCompanyInput = ({readonlyMode, formMethods, isLoading}: UserInformationProps) => {
  const {t} = useTranslation()
  const errors = formMethods.formState.errors
  const touched = formMethods.formState.touchedFields
  const classes = useStyles()

  if (isLoading) {
    return <UserInformationInputSkeleton />
  }

  return (
    <Controller
      name="companyName"
      control={formMethods.control}
      rules={{validate: (value) => handleNoSpecialCharacters(value, t)}}
      render={({field}) => (
        <TextField
          {...field}
          value={readonlyMode && !field.value ? '-' : field.value}
          data-test-id={`userInformationForm-${field.name}`}
          className={classes.textField}
          fullWidth
          id="companyName"
          label={t('userInformationForm.company')}
          autoComplete="organization"
          margin="normal"
          error={touched.companyName && !!errors.companyName}
          helperText={errors.companyName?.message}
          style={{maxWidth: 320}}
          InputProps={{readOnly: readonlyMode, disableUnderline: readonlyMode}}
        />
      )}
    />
  )
}
