import {PhoneNumberTextField} from '@hconnect/uikit'
import {TextField} from '@material-ui/core'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {UserInformationInputSkeleton} from '../Skeleton/UserInformationInputSkeleton'

import {UserInformationProps} from './types'
import {useStyles} from './UserInput.styles'

type UserMobileNumberInputProps = UserInformationProps & {
  canEditUserEmailPhone: boolean
  userMobileNumber?: string | null
}

export const UserMobileNumberInput = ({
  readonlyMode,
  formMethods,
  canEditUserEmailPhone,
  userMobileNumber,
  isLoading
}: UserMobileNumberInputProps) => {
  const {t} = useTranslation()
  const errors = formMethods.formState.errors
  const touched = formMethods.formState.touchedFields
  const classes = useStyles()
  const values = formMethods.watch()

  if (isLoading) {
    return <UserInformationInputSkeleton />
  }
  const hasError = !!errors.mobileNumber?.message
  const localKey = `errorMessages.${errors.mobileNumber?.message}`
  const helperText = hasError ? t(localKey) : undefined
  return (
    <>
      {userMobileNumber || !readonlyMode ? (
        <Controller
          name="mobileNumber"
          control={formMethods.control}
          render={({field}) => (
            <PhoneNumberTextField
              inputRef={field.ref}
              {...field}
              data-test-id={`userInformationForm-${field.name}`}
              className={classes.textField}
              fullWidth
              id="mobileNumber"
              label={t('userInformationForm.mobileNumber')}
              defaultValue={values.mobileNumber}
              onPhoneChange={(phoneNumber) => {
                formMethods.setValue('mobileNumber', phoneNumber, {shouldTouch: true})
              }}
              margin="normal"
              error={touched.mobileNumber && hasError}
              helperText={helperText}
              style={{maxWidth: 440}}
              disabled={!canEditUserEmailPhone}
              InputProps={{
                readOnly: readonlyMode,
                disableUnderline: readonlyMode,
                style: {width: readonlyMode ? 260 : 'auto'}
              }}
              options={{nationalMode: !readonlyMode, allowDropdown: !readonlyMode}}
              readonlyMode={readonlyMode}
            />
          )}
        />
      ) : (
        <TextField
          label={t('userInformationForm.mobileNumber')}
          value="-"
          InputProps={{readOnly: true, disableUnderline: true}}
          InputLabelProps={{shrink: true}}
          margin="normal"
          fullWidth
        />
      )}
    </>
  )
}
