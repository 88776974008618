import {Country} from '@hconnect/apiclient'
import {useBreakPoints} from '@hconnect/uikit'
import {Box, Button} from '@material-ui/core'
import {ArrowForward} from '@material-ui/icons'
import React from 'react'
import {UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {Announcement} from '../Announcements'
import {MessageTypes} from '../common'

import {AnnouncementDatesInput} from './inputs/AnnouncementDatesInput'
import {BusinessLinesInput} from './inputs/BusinessLinesInput'
import {CountriesInput} from './inputs/CountriesInput'
import {MessageTypeInput} from './inputs/MessageTypeInput'
import {PreviewInput} from './inputs/PreviewInput'
import {ProductsInput} from './inputs/ProductsInput'
import {ScheduleInput} from './inputs/ScheduleInput'
import {SelectPillarsInput} from './inputs/SelectPillarsInput'

export interface AnnouncementFormData {
  messageType: string
  validFrom: string | Date
  validTo: string | Date
  businessLines: ('RMC' | 'AGG' | 'CEM')[]
  products: string[]
  countries: string[]
  message: string
  pillarType: 'hConnect' | 'hProduce'
  timeFrom?: string | Date
  timeTo?: string | Date
  schedule?: string
}
interface AnnouncementFormProps {
  formMethods: UseFormReturn<AnnouncementFormData>
  setShowSummary: (showSummary: boolean) => void
  countries?: Country[]
  editAnnouncement?: Announcement
}

export const AnnouncementForm: React.FC<AnnouncementFormProps> = ({
  formMethods,
  countries = [],
  setShowSummary,
  editAnnouncement
}) => {
  const pillarType = formMethods.watch('pillarType')
  const screenSizes = useBreakPoints()
  const isTablet = ['xs', 'sm', 'md'].includes(screenSizes)
  const {t} = useTranslation()
  return (
    <Box
      data-test-id="announcement-form"
      style={{display: 'flex', flexDirection: 'column', gap: '36px'}}
    >
      <SelectPillarsInput formMethods={formMethods} />
      <Box display="flex" flexDirection="row" data-test-id="announcement-form-second-row">
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: isTablet ? '20px' : '100px'
          }}
        >
          <ProductsInput formMethods={formMethods} />
          {pillarType === 'hConnect' && <BusinessLinesInput formMethods={formMethods} />}
        </Box>
      </Box>
      <MessageTypeInput formMethods={formMethods} />
      <AnnouncementDatesInput formMethods={formMethods} />
      <PreviewInput formMethods={formMethods} editAnnouncement={editAnnouncement} />
      {formMethods.getValues().messageType === (MessageTypes.maintenance as string) && (
        <ScheduleInput formMethods={formMethods} />
      )}

      {pillarType === 'hConnect' && (
        <CountriesInput formMethods={formMethods} countries={countries} />
      )}
      {isTablet && (
        <div
          style={{
            position: 'relative',
            bottom: '-1px',
            paddingTop: 'calc(1em + 1px)',
            marginTop: '-10px',
            width: '100%',
            left: 0,
            right: 0,
            background: '#FFFFFF',
            height: '110px',
            marginLeft: 0,
            marginRight: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: 'none',
            zIndex: 0
          }}
        >
          <Button
            startIcon={<ArrowForward fontSize="small" />}
            variant="contained"
            onClick={() => {
              window.scrollTo({top: 0, left: 0})
              setShowSummary(true)
            }}
            style={{
              textTransform: 'none',
              padding: '13px 16px',
              minWidth: '240px',
              background: '#016AD4',
              color: '#FFFFFF'
            }}
          >
            {t('announcements.form.viewSummary')}
          </Button>
        </div>
      )}
    </Box>
  )
}
