import {User} from '@hconnect/apiclient'
import {useFeaturesCheckFetcher} from '@hconnect/common/components/FeaturesCheck'
import {Content, Page, ResponsiveTable, useBreakPoints} from '@hconnect/uikit'
import {TableFilterItemProps} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/Item/TableFilterItem.types'
import {TableFilterTypeProps} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/TableFilter.enum'
import {TableFilterType} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/TableFilter.types'
import {Add, Close, Mail} from '@mui/icons-material'
import {Box, Button, ClickAwayListener, Grid, IconButton, Paper} from '@mui/material'
import {useSnackbar} from 'notistack'
import React, {CSSProperties, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {api} from '../../App.store'
import {useAdminPilotUser} from '../../common/hooks/useAdminPilotUser'
import {useCountriesConfiguration} from '../../common/hooks/useCountriesConfiguration'
import {useRolesConfigurationForUserId} from '../../common/hooks/useRolesConfiguration'
import {useUserRoles} from '../../common/hooks/useUserRoles'
import {useQueryParamState} from '../../common/QueryParamStateHook'
import {SectionHeader} from '../../components/SectionHeader/SectionHeader'
import {Permission} from '../../modules/LoggedInUser'
import {
  selectLoggedInUserPermissions,
  selectLoggedInUserProfile
} from '../../modules/LoggedInUser.selectors'
import {DeleteUserDialog} from '../ManageUser/dialogs/DeleteUserDialog'
import {useDeleteUser} from '../ManageUser/hooks/useDeleteUser'

import {AudienceDetails} from './AudienceDetails'
import {ExportUserDataDialog} from './dialogs/ExportUserDataDialog'
import {useUsers} from './hooks/useUsers'
import {useUsersListFilters} from './hooks/useUsersListFilters'
import {UserDetails} from './UserDetails'
import {useStyles} from './UsersList.styles'
import {UsersTableFilter} from './UsersList.types'
import {UsersListRows} from './UsersListRows'

export const noResultsFromCustomerLookupLabel = 'usersList.lookup.noResultsFromCustomerLookup'
export const noResultsFromPayerLookupLabel = 'usersList.lookup.noResultsFromPayerLookup'

// eslint-disable-next-line complexity
export const UsersList: React.FC = () => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const history = useHistory()
  const isUserAdminPilot = useAdminPilotUser()
  const permissions = useSelector(selectLoggedInUserPermissions)
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)
  const loggedInUserId = loggedInUserProfile?.user_id
  const [shouldDisableUsersQuery, setShouldDisableUsersQuery] = useState(true)
  const [audienceDetails, setAudienceDetails] = useState(false)

  const {data: features} = useFeaturesCheckFetcher(api)

  const {data: loggedInUserRoles, isLoading: loggedInUserRolesLoading} = useUserRoles(
    loggedInUserProfile?.user_id
  )
  const isInternal =
    loggedInUserRoles && loggedInUserRoles?.length > 0
      ? loggedInUserRoles?.some((item) => item.isInternal)
      : undefined

  const isExternalUser = !!(loggedInUserProfile && !isInternal && !loggedInUserProfile?.isTester)
  const isExternalTestUser = !!(loggedInUserProfile && !isInternal && loggedInUserProfile?.isTester)

  const [areFiltersSetup, setAreFiltersSetup] = useState(false)

  const [filter, setFilter] = useQueryParamState<UsersTableFilter>({
    rowsPerPage: 10,
    page: 1,
    sortColumn: 'createdOn',
    sortDirection: 'desc',
    ...(isInternal !== undefined && (isExternalUser || isExternalTestUser) && {isTester: 'false'})
  })

  const shouldEnableNotifications =
    !!features?.find(
      (feature) =>
        feature.name === 'EnableNotifications' &&
        feature.constraints.every((constraint) => constraint.enabled)
    ) &&
    loggedInUserProfile?.hasRoles.some((role) => ['EXPORT_ADMIN', 'GLOBAL_ADMIN'].includes(role))

  const {filterInputValues, handleFilterInputValues, setFilterInputValues, setRemovedFilter} =
    useUsersListFilters(
      filter,
      setFilter,
      isExternalUser || isExternalTestUser,
      !!(loggedInUserProfile && loggedInUserRoles),
      setAreFiltersSetup
    )

  const [userDetails, setUserDetails] = useState<User>()

  const {data: countries, isLoading: countriesLoading} = useCountriesConfiguration()
  const {roles, isLoading: rolesLoading} = useRolesConfigurationForUserId()
  const {
    data: response,
    refetch: reloadUsers,
    error,
    isInitialLoading: isLoading,
    isFetching
  } = useUsers(filter, !shouldDisableUsersQuery)

  useEffect(() => {
    if (isInternal !== undefined && shouldDisableUsersQuery) {
      setShouldDisableUsersQuery(false)
    }
  }, [filter])

  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false)
  const [showExportDialog, setShowExportDialog] = useState(false)

  const {handleDeleteUser} = useDeleteUser({
    setShowDeleteUserDialog,
    userData: userDetails,
    shouldGoBack: false
  })

  const isCustomerNotFound = error?.message === noResultsFromCustomerLookupLabel
  const isPayerNotFound = error?.message === noResultsFromPayerLookupLabel

  const noResultsMessage = isCustomerNotFound
    ? t(noResultsFromCustomerLookupLabel)
    : isPayerNotFound
    ? t(noResultsFromPayerLookupLabel)
    : t('usersList.lookup.noResults')

  const setSortKey = (sortKey: string) => {
    const asc = !(filter.sortColumn === sortKey && filter.sortDirection === 'asc')
    setFilter({
      ...filter,
      sortColumn: sortKey,
      sortDirection: asc ? 'asc' : 'desc'
    })
  }

  const totalPageCount = response?.headers ? parseInt(response.headers['x-total-count'], 10) : 0

  const rowsPerPage = Number(filter.rowsPerPage)

  const SnackbarActionButton = () => (
    <Button onClick={() => void reloadUsers()} variant="text" color="primary">
      {t('usersList.retry')}
    </Button>
  )

  if (!isCustomerNotFound && error) {
    enqueueSnackbar(error.message, {
      action: <SnackbarActionButton />,
      variant: 'error'
    })
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setFilter({...filter, page: newPage})
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({...filter, rowsPerPage: parseInt(event.target.value, 10), page: 1})
  }

  const isCreateNewUserAllowed = permissions.some(
    (permission: Permission) => permission.permissionType === 'ASSIGN_ROLES'
  )

  const handleClose = () => setUserDetails(undefined)

  const handleCloseAudience = () => setAudienceDetails(false)

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>, userId: string) => {
    event.preventDefault()
    if (userId && userDetails) {
      setShowDeleteUserDialog(true)
    }
  }
  const handleEdit = (event: React.MouseEvent<HTMLButtonElement>, user: User) => {
    event.preventDefault()
    const pushLink = isUserAdminPilot ? `/managePilot/${user.user_id}` : `/manage/${user.user_id}`
    history.push(pushLink, {source: 'list', from: window.location.href})
    setUserDetails(undefined)
  }

  const handleDetails = () => {
    if (userDetails) {
      return (
        <UserDetails
          user={userDetails}
          handleClose={handleClose}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
        />
      )
    }

    if (audienceDetails && !isMobile) {
      return <AudienceDetails filters={formatAudienceFilters()} handleClose={handleCloseAudience} />
    }

    return null
  }

  const mobileAudienceStyle: CSSProperties = {
    background: '#FFFFFF',
    color: '#000000',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: '85vh',
    overflowY: 'scroll',
    zIndex: 1003
  }

  const CloseButton = ({onClose, disabled = false}) => {
    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center" height={80}>
        <div>
          <IconButton
            onClick={() => onClose()}
            style={{border: 'none', boxShadow: 'none'}}
            data-test-id="modal-close-button"
            disabled={disabled}
          >
            <Close fontSize="medium" />
          </IconButton>
        </div>
      </Box>
    )
  }
  const formatAudienceFilters = () => {
    return filterInputValues
      .filter((inputValue) => !!inputValue.value)
      .map((inputValue) => {
        if (inputValue.filterDropdownOptions && inputValue.filterDropdownOptions?.length > 0) {
          const result = inputValue.filterDropdownOptions?.find(
            (option) => option.value === inputValue.value
          )?.label
          return {
            name: inputValue.filterName || '',
            value: result || inputValue.value || ''
          }
        }
        return {
          name: inputValue.filterName || '',
          value: inputValue.value || ''
        }
      })
  }

  const mobileAudienceDetails = (
    <ClickAwayListener onClickAway={() => setAudienceDetails(false)}>
      <Box style={mobileAudienceStyle}>
        <Box style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
          <CloseButton onClose={() => setAudienceDetails(false)} />
          <Grid item lg={4} style={{height: '100%'}}>
            <Paper elevation={isMobile ? 0 : 4} style={{height: '100%'}}>
              <Box data-test-id="form-right" style={{padding: '24px 36px', height: '95%'}}>
                <Box data-test-id="form-audience" mb={1} style={{height: '100%'}}>
                  <SectionHeader title={t('usersList.audience.title')} />
                  <AudienceDetails
                    filters={formatAudienceFilters()}
                    handleClose={handleCloseAudience}
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Box>
      </Box>
    </ClickAwayListener>
  )

  return (
    <Content>
      <Page
        data-test-id="page-userslist"
        title={t('usersList.title')}
        boxed={false}
        classNameHeader={classes.pageHeader}
        headerActionContent={
          isCreateNewUserAllowed ? (
            <Button
              variant="contained"
              data-test-id="create-user-button"
              onClick={() => history.push('/createUser')}
              className={classes.createButton}
            >
              <Add className={classes.addIcon} />
              {t('usersList.createNewUser')}
            </Button>
          ) : null
        }
        {...(isMobile ? {px: 2, py: 2} : {py: 2})}
      >
        <Grid
          container
          style={{columnGap: '16px', marginTop: '22px', alignItems: 'stretch'}}
          wrap="nowrap"
        >
          <Grid
            item
            md={(userDetails || audienceDetails) && !isMobile ? 8 : 12}
            sm={12}
            xs={12}
            style={{height: '100%'}}
          >
            <Paper elevation={4} style={{padding: isMobile ? '4px 8px' : '26px 36px'}}>
              <ResponsiveTable
                keyField="id"
                columns={UsersListRows({isExternalUser, countries, roles})}
                rows={response?.data ?? []}
                onSort={(e, sortKey) => setSortKey(sortKey)}
                sortedBy={filter.sortColumn}
                sortingOrder={filter.sortDirection}
                emptyMessage={!isLoading && noResultsMessage}
                loading={
                  isLoading ||
                  isFetching ||
                  countriesLoading ||
                  rolesLoading ||
                  loggedInUserRolesLoading ||
                  !areFiltersSetup
                }
                isMobile={isMobile}
                isRowSelectable={isMobile ? undefined : () => true}
                onRowClick={(item: User) => {
                  if (item.user_id === userDetails?.user_id) {
                    setUserDetails(undefined)
                  } else {
                    setUserDetails(item)
                  }
                }}
                mobileRowDetails={handleDetails()}
                page={filter.page}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangePage={handleChangePage}
                count={totalPageCount}
                enableHorizontalScroll={true}
                enableColumnSelection={true}
                filterListInputValues={filterInputValues}
                setFilterListInputValues={setFilterInputValues}
                onFilterInputChange={(props: TableFilterItemProps) =>
                  handleFilterInputValues(props)
                }
                onFilterRemove={(filter: TableFilterType) => {
                  setRemovedFilter(filter)
                  setFilterInputValues(
                    filterInputValues.filter((inputValue) => {
                      return inputValue.filterField !== filter.filterField
                    })
                  )
                }}
                extraFilters={[
                  {
                    filterName: t('usersList.lookup.options.customerId'),
                    filterField: 'customerId',
                    filterType: TableFilterTypeProps.TEXT
                  },
                  {
                    filterName: t('usersList.lookup.options.payerId'),
                    filterField: 'payerId',
                    filterType: TableFilterTypeProps.TEXT
                  },
                  {
                    filterName: t('usersList.lookup.options.contractId'),
                    filterField: 'contractId',
                    filterType: TableFilterTypeProps.TEXT
                  },
                  {
                    filterName: t('usersList.lookup.options.plantId'),
                    filterField: 'plantId',
                    filterType: TableFilterTypeProps.TEXT
                  },
                  {
                    filterName: t('usersList.lookup.options.businessLine'),
                    filterField: 'businessLine',
                    filterType: TableFilterTypeProps.DROPDOWN,
                    filterDropdownOptions: [
                      {
                        value: 'cem',
                        label: t('usersList.businessLine.cem')
                      },
                      {
                        value: 'agg',
                        label: t('usersList.businessLine.agg')
                      },
                      {
                        value: 'rmc',
                        label: t('usersList.businessLine.rmc')
                      }
                    ]
                  }
                ]}
                storeFiltersOptions={
                  loggedInUserId ? {userId: loggedInUserId, tableName: 'users'} : undefined
                }
                tableHeight="70vh"
                stickyHeader={true}
                enablePinning={true}
                rowDetailsAvailable={true}
                actionButtons={
                  shouldEnableNotifications
                    ? [
                        {
                          name: 'audienceDetails',
                          icon: <Mail style={{color: 'rgb(1, 106, 212)'}} />,
                          onClick: () => {
                            setUserDetails(undefined)
                            setAudienceDetails(true)
                          }
                        }
                      ]
                    : undefined
                }
                {...(loggedInUserProfile?.hasRoles.includes('EXPORT_ADMIN') &&
                  (process.env.REACT_APP_STAGE === 'qa' ||
                    process.env.REACT_APP_STAGE === 'prod') && {
                    onExportData: () => setShowExportDialog(true)
                  })}
              />
            </Paper>
          </Grid>
          {!isMobile && (userDetails || audienceDetails) ? (
            <Grid item md={4} sm={0} xs={0}>
              <Paper elevation={4} style={{padding: '26px 32px 26px 32px', height: '100%'}}>
                {handleDetails()}
              </Paper>
            </Grid>
          ) : null}
        </Grid>
        {showDeleteUserDialog && (
          <DeleteUserDialog
            showDeleteUserDialog={showDeleteUserDialog}
            setShowDeleteUserDialog={setShowDeleteUserDialog}
            handleDeleteUser={handleDeleteUser}
          />
        )}
        {showExportDialog && (
          <ExportUserDataDialog
            showExportUserDataDialog={showExportDialog}
            setShowExportUserDataDialog={setShowExportDialog}
            userId={loggedInUserId}
          />
        )}
        {audienceDetails && isMobile && mobileAudienceDetails}
      </Page>
    </Content>
  )
}
