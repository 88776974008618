import {MenuItem} from '@material-ui/core'
import {Field, FieldInputProps, FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useAdminPilotUser} from '../../../common/hooks/useAdminPilotUser'
import {useCustomerByNameFeature} from '../../../hooks/useFeatures'
import {LookupDataScopeInput} from '../../../modules/DataScopeConfiguration'
import {
  ADD_CUSTOMER,
  ADD_CUSTOMER_SCOPE,
  ADD_PAYER,
  ADD_PLANT,
  GroupedRoleReducerAction
} from '../../../modules/GroupedRoleAssignmentReducer'
import {
  DataScopes,
  GroupedRoleAssignment,
  isRoleTypeUngroupable
} from '../../../modules/ManageUsers.selectors'
import {RoleCommentCategoryLookupSelect} from '../../RoleCommentCategoryLookupSelect'
import {RoleContractLookupTextField} from '../../RoleContractLookupTextField'
import {RoleCustomerLookupDropdownTextField} from '../../RoleCustomerLookupDropdownTextField'
import {RoleCustomerLookupTextField} from '../../RoleCustomerLookupTextField'
import {RolePayerLookupTextField} from '../../RolePayerLookupTextField'
import {RolePlantLookupTextField} from '../../RolePlantLookupTextField'
import {checkRequired} from '../utils'

interface Props {
  isRequired: boolean
  errorMessage: string
  configuration: LookupDataScopeInput
  groupedRoleAssignment: GroupedRoleAssignment
  dataScopeName: string
  dispatchRoleStateUpdate: React.Dispatch<GroupedRoleReducerAction>
  multiSelect: boolean
  setShowCustomerOrPayerDropdown: (show: boolean) => void
  showCustomerOrPayerDropdown: boolean
  customerAdminData?: Record<string, string | undefined>
}

export const LookupDataScope: React.FC<Props> = ({
  isRequired,
  errorMessage,
  configuration,
  groupedRoleAssignment,
  dataScopeName,
  dispatchRoleStateUpdate,
  multiSelect,
  customerAdminData,
  setShowCustomerOrPayerDropdown,
  showCustomerOrPayerDropdown
}) => {
  const {t} = useTranslation()
  const fieldName = `commonDataScopes.${dataScopeName}`
  const isUserAdminPilot = useAdminPilotUser()

  const {isCustomersByNameSearch, isLoading} = useCustomerByNameFeature()

  return (
    <Field
      key={dataScopeName}
      name={fieldName}
      validate={(value: string) => {
        if (groupedRoleAssignment.variantDataScopes.customersScopes.length > 0) {
          // Validation fine, as LOOKUP_DATA_SCOPE_TYPE is only valid for customers.
          return
        }
        if (
          dataScopeName === 'payerIds' &&
          groupedRoleAssignment.variantDataScopes.payerIds &&
          groupedRoleAssignment.variantDataScopes.payerIds.length > 0
        ) {
          // Validation fine, as LOOKUP_DATA_SCOPE_TYPE is only valid for payers.
          return
        }
        if (
          dataScopeName === 'plantIds' &&
          groupedRoleAssignment.variantDataScopes.plantIds &&
          groupedRoleAssignment.variantDataScopes.plantIds.length > 0
        ) {
          // Validation fine, as LOOKUP_DATA_SCOPE_TYPE is only valid for plants.
          return
        }

        return checkRequired(
          isRequired,
          multiSelect,
          t('roleAssignment.dataScopeRequired', {
            dataScope: t(`roleAssignment.dataScope.${dataScopeName}`)
          })
        )(value)
      }}
    >
      {
        // eslint-disable-next-line complexity
        ({
          field,
          form
        }: {
          field: FieldInputProps<string[]>
          form: FormikProps<{commonDataScopes: DataScopes}>
        }) => {
          const dependenciesMet =
            configuration.dependsOn !== undefined
              ? !configuration.dependsOn
                  .map((dep) => form.values.commonDataScopes[dep])
                  .some((dependency) => dependency === undefined)
              : true

          const customerAdminPayerDependenciesMet =
            isUserAdminPilot && groupedRoleAssignment.variantDataScopes.customersScopes.length === 0
          // FIXME Add properly working customers and payers list
          // const customersList =
          //   field?.value?.map?.((customerId: string) => getCustomerById(customers, customerId)) || []
          const customersList = []

          // const payersList =
          //   field?.value?.map?.((payerId: string) => getPayerById(payers, payerId)) || []

          const payersList = []
          if (
            dataScopeName === 'payerIds' &&
            (isUserAdminPilot
              ? showCustomerOrPayerDropdown ||
                groupedRoleAssignment.variantDataScopes.payerIds?.length === undefined ||
                groupedRoleAssignment.variantDataScopes.payerIds?.length === 0
              : true)
          ) {
            return (
              <RolePayerLookupTextField
                InputLabelProps={{required: isRequired}}
                payers={payersList}
                groupedRoleAssignment={groupedRoleAssignment}
                onChangePayerIds={(payerIds: string[]) => {
                  dispatchRoleStateUpdate({
                    type: ADD_PAYER,
                    payload: payerIds[0]
                  })
                }}
                disabled={isUserAdminPilot ? !customerAdminPayerDependenciesMet : !dependenciesMet}
                name={field.name}
                margin="normal"
                fullWidth
                helperText={errorMessage}
                dataScopeName={dataScopeName}
                customerAdminData={customerAdminData}
                setShowCustomerOrPayerDropdown={setShowCustomerOrPayerDropdown}
              />
            )
          }

          if (dataScopeName === 'plantIds' || dataScopeName === 'plantId') {
            return (
              <RolePlantLookupTextField
                InputLabelProps={{required: isRequired}}
                groupedRoleAssignment={groupedRoleAssignment}
                dataScopeName={dataScopeName}
                disabled={!dependenciesMet}
                helperText={errorMessage}
                onChangePlantIds={(plantId: string) => {
                  dispatchRoleStateUpdate({
                    type: ADD_PLANT,
                    payload: plantId
                  })
                }}
              />
            )
          }

          if (dataScopeName === 'commentCategories') {
            return (
              <RoleCommentCategoryLookupSelect
                groupedRoleAssignment={groupedRoleAssignment}
                dataScopeName={dataScopeName}
                disabled={!dependenciesMet}
                onChange={(commentCategory: string) => {
                  dispatchRoleStateUpdate({
                    type: 'ADD_COMMENT_CATEGORY',
                    payload: commentCategory
                  })
                }}
              >
                {/* This is an annoying manual implementation but it's better than typing it into a free text field*/}
                <MenuItem value={'Assets'}>
                  {t('roleAssignment.commentCategoryItems.assets')}
                </MenuItem>
                <MenuItem value={'AssetOperationTimes'}>
                  {t('roleAssignment.commentCategoryItems.assetoperationtimes')}
                </MenuItem>
                <MenuItem value={'POMQuestions'}>
                  {t('roleAssignment.commentCategoryItems.POMquestions')}
                </MenuItem>
                <MenuItem value={'MaterialOrders'}>
                  {t('roleAssignment.commentCategoryItems.materialorders')}
                </MenuItem>
              </RoleCommentCategoryLookupSelect>
            )
          }
          if (dataScopeName === 'contractIds') {
            return (
              <RoleContractLookupTextField
                InputLabelProps={{required: isRequired}}
                groupedRoleAssignment={groupedRoleAssignment}
                dataScopeName={dataScopeName}
                disabled={!dependenciesMet}
                helperText={errorMessage}
                onChangeContractIds={(contractId) => {
                  dispatchRoleStateUpdate({
                    type: ADD_CUSTOMER_SCOPE,
                    payload: {
                      contractId
                    }
                  })
                }}
              />
            )
          }

          const isCustomerSelected =
            groupedRoleAssignment.variantDataScopes.customersScopes.length > 0

          const hide = isRoleTypeUngroupable(groupedRoleAssignment.roleType) && isCustomerSelected

          if (hide) {
            return null
          }
          if (isLoading) {
            return null
          } else if (isCustomersByNameSearch) {
            return (
              <RoleCustomerLookupDropdownTextField
                InputLabelProps={{
                  required: isRequired
                }}
                customers={customersList}
                groupedRoleAssignment={groupedRoleAssignment}
                onChangeCustomerIds={(customerIds) => {
                  dispatchRoleStateUpdate({
                    type: ADD_CUSTOMER,
                    payload: customerIds[0]
                  })
                }}
                disabled={!dependenciesMet}
                name={field.name}
                margin="normal"
                fullWidth
                helperText={errorMessage}
                dataScopeName={dataScopeName}
              />
            )
          } else {
            return !isUserAdminPilot ||
              showCustomerOrPayerDropdown ||
              (dataScopeName !== 'payerIds' &&
                groupedRoleAssignment.variantDataScopes.customersScopes.length === 0) ? (
              <RoleCustomerLookupTextField
                InputLabelProps={{
                  required: isRequired
                }}
                customers={customersList}
                groupedRoleAssignment={groupedRoleAssignment}
                onChangeCustomerIds={(customerIds) => {
                  dispatchRoleStateUpdate({
                    type: ADD_CUSTOMER,
                    payload: customerIds[0]
                  })
                }}
                disabled={isUserAdminPilot ? !isUserAdminPilot : !dependenciesMet}
                name={field.name}
                margin="normal"
                fullWidth
                helperText={errorMessage}
                dataScopeName={dataScopeName}
                customerAdminData={customerAdminData}
                showCustomerOrPayerDropdown={showCustomerOrPayerDropdown}
                setShowCustomerOrPayerDropdown={setShowCustomerOrPayerDropdown}
              />
            ) : null
          }
        }
      }
    </Field>
  )
}
