import {Country, User} from '@hconnect/apiclient'
import {getMarketLabelForGermany} from '@hconnect/common/utils/markets'
import {sortByPriority} from '@hconnect/common/utils/sorting'
import {Link} from '@material-ui/core'
import moment from 'moment'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {UserInformationFormData} from '../UserInformationForm.types'
import {
  compareMarketByLabel,
  filterMarketsForMarketSelection,
  filterMarketsForPrimaryOrgUnitSelection,
  getBrandingsForCountry,
  getMarketLabel
} from '../utils/utils'

export const useUserInformationFormData = (values: UserInformationFormData, user?: User) => {
  const {availableCountries} = values
  const {t} = useTranslation()
  const history = useHistory()

  const countriesIds = useMemo(
    () => availableCountries?.map((country: Country) => country.countryCode) || [],
    [availableCountries]
  )

  const brandings = useMemo(
    () => getBrandingsForCountry(values.availableCountries || [], values.country),
    [values.availableCountries, values.country]
  )

  const selectedCountry = availableCountries?.find(
    (country) => country.countryCode === values.country
  )

  const primaryOrgUnitsMarketsSelectValues = useMemo(() => {
    return (
      selectedCountry?.markets
        .filter(filterMarketsForPrimaryOrgUnitSelection)
        .map((market) => ({
          value: market.marketCode,
          label: market.marketName
        }))
        .sort(compareMarketByLabel) || []
    )
  }, [selectedCountry])

  const gerBusinessLinePriority = {
    Zement: 1,
    Mineralik: 2,
    Beton: 3
  }

  const hProduceMarket = {
    value: 'hproduce',
    label: t('userInformationForm.userCreationInfo.hProduceUser')
  }

  const marketSelectValues = useMemo(() => {
    return (
      selectedCountry?.markets
        .filter(filterMarketsForMarketSelection)
        .map((market) => {
          return {
            value: market.marketId,
            label:
              selectedCountry.countryCode === 'DE'
                ? getMarketLabelForGermany(market)
                : getMarketLabel(market)
          }
        })
        .concat(
          selectedCountry.countryCode === 'US' || selectedCountry.countryCode === 'CA'
            ? [hProduceMarket]
            : []
        )
        .sort((a, b) => {
          if (selectedCountry.countryCode === 'DE') {
            const regex: RegExp = /\(([^)]+)\)/
            const businessLineA = regex.exec(a.label)?.[1] || ''
            const businessLineB = regex.exec(b.label)?.[1] || ''

            return sortByPriority(
              businessLineA,
              businessLineB,
              a.label,
              b.label,
              gerBusinessLinePriority
            )
          } else {
            return compareMarketByLabel(a, b)
          }
        }) || []
    )
  }, [selectedCountry])

  const userCountry = availableCountries?.find((country) => country.countryCode === user?.country)

  const userMarket = userCountry?.markets.find((market) => market.marketId === user?.marketId)
  const marketName = userMarket
    ? getMarketLabel(userMarket)
    : (userCountry?.countryCode === 'US' || userCountry?.countryCode === 'CA') && !userMarket
    ? hProduceMarket.label
    : user?.marketId

  const userPrimaryOrgUnitMarket = userCountry?.markets.find(
    (market) => market.marketCode === user?.primaryOrganisationalUnit
  )

  const primaryOrganisationalUnitLabel = userPrimaryOrgUnitMarket
    ? userPrimaryOrgUnitMarket.marketName
    : user?.primaryOrganisationalUnit

  const createdOnText =
    user &&
    t('userInformationForm.userCreationInfo.createdOn', {
      createdOn: moment(user.createdOn).format('DD/MM/YYYY'),
      interpolation: {escapeValue: false}
    })

  const createdByText = user?.createdBy ? t('userInformationForm.userCreationInfo.createdBy') : ''
  const createdByLink = user?.createdBy ? (
    <Link
      href={`/manage/${user.createdBy}`}
      onClick={(e: any) => {
        e.preventDefault()
        history.push(`/manage/${user.createdBy}`)
      }}
    >
      {user.createdBy}
    </Link>
  ) : (
    ''
  )

  const createdViaText =
    user &&
    t('userInformationForm.userCreationInfo.createdVia', {
      creationChannel: t(
        `creationChannel.${user.creationChannel || 'unknown'}`,
        String(user.creationChannel)
      ),
      interpolation: {escapeValue: false}
    })

  const createdInText =
    user &&
    t('userInformationForm.userCreationInfo.createdIn', {
      creationProduct: t(
        `creationProduct.${user.creationProduct || 'unknown'}`,
        String(user.creationProduct)
      ),
      interpolation: {escapeValue: false}
    })

  return {
    countriesIds,
    brandings,
    primaryOrgUnitsMarketsSelectValues,
    marketSelectValues,
    selectedCountry,
    availableCountries,
    marketName,
    primaryOrganisationalUnitLabel,
    createdOnText,
    createdViaText,
    createdInText,
    createdByLink,
    createdByText
  }
}
