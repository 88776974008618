import {TextField} from '@material-ui/core'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {UserInformationInputSkeleton} from '../Skeleton/UserInformationInputSkeleton'
import {handleNoSpecialCharacters} from '../utils/handleNoSpecialCharacters'

import {UserInformationProps} from './types'
import {useStyles} from './UserInput.styles'

export const UserNameInput = ({readonlyMode, formMethods, isLoading}: UserInformationProps) => {
  const {t} = useTranslation()
  const errors = formMethods.formState.errors
  const touched = formMethods.formState.touchedFields
  const classes = useStyles()

  if (isLoading) {
    return <UserInformationInputSkeleton />
  }

  return (
    <Controller
      name="name"
      control={formMethods.control}
      rules={{
        validate: (value) => handleNoSpecialCharacters(value, t)
      }}
      render={({field}) => (
        <TextField
          {...field}
          data-test-id={`userInformationForm-${field.name}`}
          className={classes.textField}
          fullWidth
          label={t('userInformationForm.name')}
          autoComplete="name"
          margin="normal"
          error={touched.name && !!errors.name}
          helperText={errors.name?.message}
          style={{maxWidth: 400}}
          InputProps={{readOnly: readonlyMode, disableUnderline: readonlyMode}}
        />
      )}
    />
  )
}
