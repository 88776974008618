import {Country} from '@hconnect/apiclient'
import {Typography} from '@hconnect/uikit'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {countryDataByCountryCode} from '../../../../common/utils'
import {CheckboxForm} from '../Announcements.components'

import {AnnouncementFormProps} from './types'

export type CountriesInputProps = AnnouncementFormProps & {
  countries: Country[]
}
export const CountriesInput = ({formMethods, countries}: CountriesInputProps) => {
  const {t} = useTranslation()
  const selectedCountries = formMethods.getValues('countries')
  const products = formMethods.getValues('products')
  const countriesData = window.intlTelInputGlobals.getCountryData()

  const countryDataFilter = (countryId?: string | null) =>
    countryDataByCountryCode(countriesData, countryId)
  countries = countries
    .filter(
      (country) =>
        country.liveProducts.filter((value) =>
          products.includes('MHC') ? true : products.includes(value)
        ).length > 0
    )
    .sort((a, b) => a.countryName.localeCompare(b.countryName))

  const [allCountriesSelected, setAllCountriesSelected] = useState(-1)

  useEffect(() => {
    if (selectedCountries.length === countries.length) {
      setAllCountriesSelected(1)
    } else if (selectedCountries.length > 0) {
      setAllCountriesSelected(0)
    } else {
      setAllCountriesSelected(-1)
    }
  }, [countries, selectedCountries])

  const onSelectAllCountries = (checked: boolean) => {
    if (checked) {
      setAllCountriesSelected(1)
      formMethods.setValue(
        'countries',
        countries.map((c) => c.countryCode)
      )
    } else {
      setAllCountriesSelected(-1)
      formMethods.setValue('countries', [])
    }
  }

  return (
    <Box display="flex" flexDirection="column" data-test-id="announcement-form-countries-row">
      <Typography style={{fontSize: '18px', fontWeight: 600}}>Country</Typography>
      {countries.length > 0 ? (
        <Controller
          control={formMethods.control}
          name="countries"
          rules={{
            validate: (value) => value.length > 0
          }}
          render={({field}) => (
            <FormControl fullWidth>
              <Box display="flex" flexDirection="row">
                {countries.length > 3 && (
                  <FormControlLabel
                    style={{marginLeft: -10}}
                    control={
                      <CheckboxForm
                        dataTestId="select-all-countries-checkbox"
                        indeterminate={allCountriesSelected === 0}
                        checked={allCountriesSelected > -1}
                        onChange={(_, checked) => onSelectAllCountries(checked)}
                      />
                    }
                    label={t('announcements.selectAllCountries')}
                  />
                )}
              </Box>
              <FormGroup row>
                <Grid container wrap="wrap">
                  {countries.map((country) => (
                    <Grid key={country.countryCode} item md={3} xs={6}>
                      <FormControlLabel
                        control={
                          <CheckboxForm
                            name={country.countryCode}
                            dataTestId={`${country.countryCode}-checkbox`}
                            checked={field.value.includes(country.countryCode)}
                            onChange={(
                              _: React.ChangeEvent<HTMLInputElement>,
                              checked: boolean
                            ) => {
                              if (checked) {
                                const values = new Set(field.value)
                                values.add(country.countryCode)
                                field.onChange([...values])
                              } else {
                                const values = new Set(field.value)
                                values.delete(country.countryCode)
                                field.onChange([...values])
                              }
                            }}
                          />
                        }
                        label={countryDataFilter(country.countryCode)?.name || country.countryName}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
              {formMethods.formState.errors.countries?.type === 'validate' ? (
                <FormHelperText
                  data-test-id="countries-helper-text"
                  style={{marginLeft: 0}}
                  component="div"
                  error={formMethods.formState.errors.countries?.type === 'validate'}
                >
                  {t('announcements.form.validateCountries')}
                </FormHelperText>
              ) : null}
            </FormControl>
          )}
        />
      ) : (
        <Typography style={{marginTop: '12px', marginLeft: '2px'}}>
          {t('announcements.form.noCountries')}
        </Typography>
      )}
    </Box>
  )
}
